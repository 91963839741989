import { ComingSoon } from './ComingSoon';
import { Footer } from './Footer';
import { GotQuestion } from './GotQuestion';
import { HeroSection } from './HeroSection';
import { ReadyToStart } from './ReadyToStart';
import { Steps } from './Steps';

export function LandingView() {
  return (
    <div className="flex flex-col overflow-y-auto pb-[60px]">
      <HeroSection />
      <Steps />
      <ComingSoon />
      <GotQuestion />
      <ReadyToStart />
      <Footer />
    </div>
  );
}
