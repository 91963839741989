import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import type { Form, Question } from '@/forms/domains/form';
import { useRemoveQuestion } from '@/response/mutations/useRemoveQuestion';
import { type ReactNode, useState } from 'react';

export function QuestionItemContextMenu({
  question,
  form,
  children,
}: {
  form: Form;
  question: Question;
  children: ({ open }: { open: boolean }) => ReactNode;
}) {
  const isPublished = form.status === 'published';
  const removeQuestion = useRemoveQuestion({
    formId: form.id,
    questionId: question.id,
  });
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger className="outline-none hover:bg-[#2A2B2F] data-[state='open']:bg-[#2A2B2F] rounded-[4px] transition-colors">
        {children({ open })}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() => removeQuestion.mutateAsync()}
          disabled={isPublished}
        >
          Remove
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
