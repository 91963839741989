import type { Form, PositionedField, Question } from '@/forms/domains/form';
import { useQuestions } from '@/forms/queries/useQuestions';
import { calculateQuestionPosition } from '@/lib/questions';
import { FormHeader } from './FormHeader';
import { MultipleChoiceField } from './fields/MultipleChoiceField';
import { NewPageField } from './fields/NewPageField';
import { NumericalField } from './fields/NumericalField';
import { ParagraphField } from './fields/ParagraphField';
import { ShortTextField } from './fields/ShortTextField';
import { SingleChoiceField } from './fields/SingleChoiceField';

export function FormBuilder({ form }: { form: Form }) {
  const questions = useQuestions({ formId: form.id });
  const normalizedFields = calculateQuestionPosition(questions.data ?? []);

  return (
    <div className="w-full p-[24px] pb-[148px] flex flex-col gap-[36px]">
      <FormHeader form={form} />
      {normalizedFields.map((field) => (
        <div key={field.id} id={field.id}>
          <QuestionContent field={field} form={form} />
        </div>
      ))}
    </div>
  );
}

function QuestionContent({
  field,
  form,
}: { field: PositionedField<Question>; form: Form }) {
  if (field.type === 'short_text') {
    return <ShortTextField field={field} />;
  }

  if (field.type === 'paragraph') {
    return <ParagraphField field={field} />;
  }

  if (field.type === 'numerical') {
    return <NumericalField field={field} />;
  }

  if (field.type === 'multi_choice_list') {
    return <MultipleChoiceField field={field} form={form} />;
  }

  if (field.type === 'single_choice_list') {
    return <SingleChoiceField field={field} form={form} />;
  }

  if (field.type === 'new_page') {
    return <NewPageField item={field} />;
  }

  return null;
}
