import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import type { Form } from '../domains/form';

export function useMyForms() {
  const { address } = useAccount();

  return useSuspenseQuery({
    queryKey: ['myForms', address],
    queryFn: async () => {
      const request = await getFormsRequest().get<APIResponse<Form[]>>(
        '/users/forms',
        { params: { Page: 1, Items: 10 } }, // TODO - add pagination, fix undefined address
      );

      return request.data.data;
    },
  });
}
