import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useMutation } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';

export function useSubmitResponse({ formId }: { formId: string }) {
  const [, setDraftResponse] = useLocalStorage<Record<string, string>>(
    `form-${formId}-draft`,
    {},
  );

  return useMutation({
    mutationKey: ['submitResponse'],
    mutationFn,
    onSuccess: () => {
      setDraftResponse({});
    },
  });

  async function mutationFn(res: Record<string, string | number>) {
    return getFormsRequest().post<APIResponse<null>>(
      `/public/forms/${formId}/responses`,
      res,
    );
  }
}
