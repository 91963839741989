import { CreateFormDialog } from '@/components/CreateFormDialog';
import { SidebarLeft } from '@/components/SidebarLeft';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useMyForms } from '@/forms/queries/useMyForms';
import { isAPIResponse } from '@/lib/request';
import { DefaultLoaderFallback, withFallback } from '@/lib/withFallback';
import { useSearch } from '@tanstack/react-router';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { FormTable } from './FormTable';
import { InvalidSession } from './InvalidSession';

function Page() {
  const forms = useMyForms();
  const view = useSearch({
    from: '/forms',
    select: (search) => search.view,
  });
  const [searchValue, setSearchValue] = useState('');
  const [openCreateFormDialog, setOpenCreateFormDialog] = useState(false);

  return (
    <div className="flex h-full w-full">
      <SidebarLeft searchValue={searchValue} onSearchChange={setSearchValue} />
      <ScrollArea className="flex flex-col w-full px-[36px] pb-[80px]">
        <div className="text-[20px] leading-[24px] tracking-[.25px] font-[400] text-[#CAC5CD] text-left py-[16px]">
          My workspace
        </div>
        <div className="pb-[24px]">
          <CreateFormDialog
            open={openCreateFormDialog}
            onChange={setOpenCreateFormDialog}
          />
        </div>
        <FormTable formsData={getFormsData()} />
      </ScrollArea>
    </div>
  );

  function getFormsData() {
    if (view === 'drafts') {
      return forms.data?.filter(
        (form) => form.status === 'draft' && form.title.includes(searchValue),
      );
    }

    return forms.data?.filter((form) => form.title.includes(searchValue));
  }
}

function DefaultErrorFallback(props: FallbackProps) {
  if (
    isAxiosError(props.error) &&
    isAPIResponse(props.error.response?.data) &&
    props.error.response?.data.message === 'invalid token'
  ) {
    return <InvalidSession resetErrorBoundary={props.resetErrorBoundary} />;
  }

  return (
    <div>
      <h1>Something went wrong</h1>
    </div>
  );
}

export const DashboardView = withFallback(
  Page,
  DefaultLoaderFallback,
  DefaultErrorFallback,
);
