import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

interface Tab {
  id: string;
  label: string;
}

interface TabProps {
  selectedTab: string;
  tabs: Tab[];
  layoutId: string;
  onSelect: (tabId: string) => void;
}

export function EditorTabs({
  selectedTab,
  onSelect,
  layoutId,
  tabs,
}: TabProps) {
  return (
    <div className="flex">
      {tabs.map((tab) => (
        <div key={tab.id} className="flex flex-col">
          {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
          <button
            key={tab.id}
            onClick={() => onSelect(tab.id)}
            className={cn(
              selectedTab === tab.id
                ? "text-[#FFFFFF]"
                : "text-[#B6B6B6] hover:!text-[#FFFFFF]",
              "px-3 pt-[22px] pb-[15px] text-[14px] leading-[24px] tracking-[.5px] font-[400] transition",
            )}
            style={{ WebkitTapHighlightColor: "transparent" }}
          >
            {tab.label}
          </button>
          {selectedTab === tab.id && (
            <motion.div
              className="min-h-[6px] rounded-[8px] bg-[#6750A4] z-10"
              transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
              layoutId={layoutId}
            />
          )}
        </div>
      ))}
    </div>
  );
}
