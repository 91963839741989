import { getFormsRequest } from '@/lib/request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Question } from '../domains/form';

export type UpdateParams = Partial<Question> & { questionId: string };

export function useUpdateQuestion({ formId }: { formId: string }) {
  const client = useQueryClient();

  return useMutation({
    mutationKey: ['updateQuestion'],
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['questions', formId] });
    },
  });

  async function mutationFn({ questionId, ...updateParams }: UpdateParams) {
    const result = await getFormsRequest().patch(
      `/forms/${formId}/questions/${questionId}`,
      updateParams,
    );

    return result.data;
  }
}
