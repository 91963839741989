import { Icon } from '@/components/Icon';
import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

export function GotQuestion() {
  return (
    <section className="flex justify-center pb-[72px] pt-[32px]">
      <div className="flex flex-col max-w-[997px] items-center gap-[20px] w-full">
        <div className="text-[#D0BCFF] text-[40px] leading-[135%] font-[400] text-left">
          <span className="text-[#fff]">Got</span> Question?
        </div>
        <div className="flex flex-col gap-[10px] w-full px-[16px] lg:px-[80px]">
          <Question
            title="Where is my data stored?"
            description="The forms and responses are securely stored in a database. Our platform converts this data into a publicly available dataset listed on our marketplace."
          />
          <Question
            title="Can I delete my form and dataset?"
            description="Yes, the form creator (and dataset owner) has full access rights to delete both the form and the dataset."
          />
          <Question
            title="Is every question in the form a mandatory field for the user?"
            description="No, it is not. As a form creator your have the option to select whether an answer is required or not."
          />
          <Question
            title="Is there any cost to use the platform?"
            description="No, the platform is completely free to use. There is no cost for creating a form or responding to one."
          />
          <Question
            title="Why do I need a wallet as a form creator?"
            description="A wallet address is required to distribute rewards. Your wallet's public address will not be displayed in the dataset and will only be used for access and receiving payments."
          />
          <Question
            title="Why do I need a wallet as a form responder?"
            description="Responders earn rewards when a dataset is purchased. To ensure proper distribution of rewards, a public wallet address is required. This address will not be visible in the dataset."
          />
        </div>
      </div>
    </section>
  );
}

interface QuestionProps {
  title: string;
  description: string;
}

function Question(props: QuestionProps) {
  const [open, setOpen] = useState(false);

  return (
    <button
      type="button"
      className="flex border border-[#22212F] bg-white/[0.02] px-[15px] lg:px-[50px] pt-[24px] pb-[24px] flex-col gap-[28px] w-full"
      onClick={() => setOpen((prev) => !prev)}
    >
      <div className="flex justify-between gap-[10px] w-full">
        <div className="text-[22px] leading-[31.9px] text-[#fff] text-left">
          {props.title}
        </div>
        <div>
          <Icon
            size={24}
            name="chevron-down-rounded"
            className={cn(
              'transition-transform duration-300',
              open && 'rotate-180',
            )}
          />
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ height: 0, opacity: 0, paddingBottom: 0 }}
            animate={{
              height: 'auto',
              opacity: 1,
              paddingBottom: 21,
              transition: { duration: 0.3 },
            }}
            exit={{
              height: 0,
              opacity: 0,
              paddingBottom: 0,
              transition: {
                duration: 0.6,
                height: { duration: 0.3, delay: 0.3, ease: 'linear' },
                opacity: { duration: 0.3 },
                paddingBottom: { duration: 0.3, ease: 'linear' },
              },
            }}
            className="text-[16px] leading-[23.2px] text-[#BDB7C4] font-[400] text-left"
          >
            {props.description}
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}
