import { useUpdateForm } from '@/forms/mutations/useUpdateForm';
import { useForm } from '@/forms/queries/useForm';
import { ReloadIcon } from '@radix-ui/react-icons';
import dayjs from 'dayjs';
import { type ReactNode, useState } from 'react';
import { Icon } from './Icon';
import { DatePicker } from './ui/DatePicker';
import { Button } from './ui/button';
import { Checkbox } from './ui/checkbox';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

export function EditFormCampaignPeriod({
  children,
  formId,
}: { children: ReactNode; formId: string }) {
  const form = useForm({ id: formId });
  const [time, setTime] = useState(form.data?.campaignEndsAt);
  const updateForm = useUpdateForm({ formId });

  if (!form.data) return null;

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <div className="pb-[16px]">
          <DialogHeader>
            <div className="flex gap-[16px] items-center">
              <div className="bg-[#D0BCFF] rounded-[8px] p-[20px]">
                <Icon name="pen-to-square" size={16} color="#121212" />
              </div>
              <DialogTitle>Campaign period</DialogTitle>
            </div>
          </DialogHeader>
          <div className="flex flex-col gap-[16px] pt-[36px]">
            <DatePicker value={time} onChange={setTime} />
            <div className="flex gap-[15px] items-center">
              <Checkbox
                id="campaignEndsAt"
                checked={!time}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setTime(null);
                  } else {
                    setTime(dayjs().toString());
                  }
                }}
              />
              <label
                htmlFor="campaignEndsAt"
                className="text-[16px] font-[400] leading-[24px] tracking-[.5px] text-[#E6E0E9] cursor-pointer"
              >
                Undefined period
              </label>
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              disabled={
                updateForm.isPending || form.data.campaignEndsAt === time
              }
              type="submit"
              onClick={onUpdate}
            >
              {updateForm.isPending && (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  &nbsp;
                </>
              )}
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  function onUpdate() {
    if (!time) {
      updateForm.mutateAsync({ campaignEndsAt: 'undefined' });
    } else {
      updateForm.mutateAsync({
        campaignEndsAt: dayjs(time).toISOString(),
      });
    }
  }
}
