import { twMerge } from 'tailwind-merge';
import { Icon } from './Icon';
import { SearchFormInput } from './SearchFormInput';
import { Separator } from './ui/separator';

export function ReadonlySidebarLeft() {
  return (
    <div className="sticky w-full h-full max-w-[245px] bg-[#212226] flex flex-col gap-[16px]">
      <SearchFormInput
        value=""
        onChange={() => {}}
        className="pl-[24px] pr-[12px] py-[16px]"
      />
      <SidebarItem
        title="My workspace"
        icon="user-filled"
        count={0}
        className="py-[11px] px-[24px]"
      />

      <div className="px-[16px]">
        <Separator className="bg-[#383838]" />
      </div>

      <SidebarItem title="Drafts" count={0} className="py-[11px] px-[24px]" />
    </div>
  );
}

interface SidebarItemProps {
  title: string;
  count?: number;
  icon?: string;
  className?: string;
}

function SidebarItem({ title, icon, count, className }: SidebarItemProps) {
  return (
    <div className={twMerge('flex items-center justify-between', className)}>
      <div className="flex items-center gap-[12px]">
        {icon && <Icon name={icon} size={16} color="#FFF" />}
        <div className="text-[#B6B6B6] text-[14px] leading-[20px] font-[400]">
          {title}
        </div>
      </div>
      {count !== undefined && (
        <div className="text-[#79747E] text-[14px] leading-[20px] font-[400]">
          {count}
        </div>
      )}
    </div>
  );
}
