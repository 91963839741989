import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useQuery } from '@tanstack/react-query';

export interface FormStats {
  total_responses: number;
}

export function useFormStats({ formId }: { formId: string }) {
  return useQuery({
    queryKey: ['formStats', formId],
    queryFn,
    enabled: !!formId,
  });

  async function queryFn() {
    const response = await getFormsRequest().get<APIResponse<FormStats>>(
      `/forms/${formId}/responses/stats`,
    );

    return response.data.data;
  }
}
