import { useForm } from '@/forms/queries/useForm';
import { useNavigate, useParams } from '@tanstack/react-router';
import type { ReactNode } from 'react';
import { Breadcrumbs } from './Breadcrumbs';
import { EditorTabs } from './EditorTabs';
import { FormPublishDialog } from './FormPublishDialog';
import { MutationDetector } from './MutationDetector';

const editorTabs = [
  { id: 'edit', label: 'Create' },
  { id: 'schema', label: 'Schema' },
  { id: 'preview', label: 'Preview' },
];

export function FormEditorPage({ children }: { children: ReactNode }) {
  const { id: formId, mode } = useParams({ from: '/forms/$id/$mode' });
  const form = useForm({ id: formId });
  const navigate = useNavigate();

  if (!form.data) return null;

  return (
    <div className="h-full max-w-[1440px] w-full">
      <div className="flex justify-between bg-[#212226] h-[68px] px-[24px] border-b border-[#121212]">
        <div className="flex gap-[16px]">
          <Breadcrumbs />
          <MutationDetector />
        </div>
        <EditorTabs
          tabs={editorTabs}
          selectedTab={mode}
          layoutId="form-editor-tabs"
          onSelect={(tabId) => {
            navigate({
              to: '/forms/$id/$mode',
              params: { id: formId, mode: tabId },
            });
          }}
        />
        <div className="py-[14px]">
          <FormPublishDialog formId={formId} />
        </div>
      </div>
      <div className="h-full">{children}</div>
    </div>
  );
}
