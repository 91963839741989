import type { ComponentType, ReactNode } from "react";
import { EditFormCampaignPeriod } from "../EditFormCampaignPeriod";
import { Icon } from "../Icon";

export function FormSettings({ formId }: { formId: string }) {
  return (
    <div className="pt-[16px]">
      <SettingsButton
        formId={formId}
        label="Campaign Period"
        ActionButton={EditFormCampaignPeriod}
      />
    </div>
  );
}

function SettingsButton({
  formId,
  label,
  ActionButton,
}: {
  formId: string;
  label: string;
  ActionButton: ComponentType<{
    children: ReactNode;
    formId: string;
  }>;
}) {
  return (
    <div className="flex justify-between items-center px-[24px] py-[10px] hover:bg-[#2A2B2F] group">
      <div className="text-[14px] font-[300] leading-[20px] tracking-[.25px] text-[#FFFFFF]">
        {label}
      </div>
      <ActionButton formId={formId}>
        <Icon
          name="pen-to-square"
          size={16}
          className="group-hover:!opacity-100 opacity-0 transition-opacity cursor-pointer"
        />
      </ActionButton>
    </div>
  );
}
