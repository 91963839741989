export interface ContentDefinition {
  id: string;
  title: string;
  backgroundColor: string;
  description: string;
  icon?: string;
  width?: number;
  height?: number;
}

export const questionDefinition: ContentDefinition[] = [
  {
    id: 'multi_choice_list',
    title: 'Multiple Choice',
    backgroundColor: '#FF839B',
    description:
      'Allow respondents to select one or more options from a predefined list.',
    icon: 'multiple-choice-type',
    width: 12,
    height: 9,
  },
  {
    id: 'single_choice_list',
    title: 'Single Choice',
    backgroundColor: '#FFD8E4',
    description:
      'Enable respondents to select only one option from a list of choices.',
    icon: 'single-choice-type',
    width: 16,
    height: 16,
  },
  {
    id: 'short_text',
    title: 'Short text',
    backgroundColor: '#FFEB83',
    description:
      'Offer a small text box for brief and concise answers, suitable for responses like names or single words.',
    icon: 'textfield-type',
    width: 12.25,
    height: 14,
  },
  {
    id: 'paragraph',
    title: 'Paragraph',
    backgroundColor: '#7CCEE0',
    description:
      'Provide a large text box for respondents to give detailed and long-form answers.',
    icon: 'paragraph-type',
    width: 12.25,
    height: 14,
  },
  // TODO - Enable date type
  // {
  //   id: "date",
  //   title: "Date",
  //   backgroundColor: "#BBCAFF",
  //   description:
  //     "Ask respondents to provide a specific date using a calendar picker or date field.",
  //   icon: "date-type",
  //   width: 14,
  //   height: 14,
  // },
  {
    id: 'numerical',
    title: 'Numerical',
    backgroundColor: '#8DEFB4',
    description:
      'Request respondents to enter a number, ideal for quantities, measurements, or other numerical data.',
    width: 14,
    height: 16,
    icon: 'numerical-type',
  },
  {
    id: 'new_page',
    title: 'New page',
    backgroundColor: '#2B2930',
    description:
      'A new section will be added, showing the next question on a new page',
  },
];

export function getContentDefinition(type: ContentDefinition['id']) {
  return questionDefinition.find((item) => item.id === type);
}
