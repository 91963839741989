import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useChainId } from 'wagmi';

export function useSignUser() {
  const queryClient = useQueryClient();
  const chainId = useChainId();
  const [_, setToken] = useLocalStorage('token', '');
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['signUser'],
    mutationFn,
    onSuccess: async (data) => {
      setToken(data.data);
      await queryClient.invalidateQueries({ queryKey: ['fallback'] });
      navigate({ to: '/forms' });
    },
  });

  async function mutationFn() {
    const signature = await createEthereumSignedAuthToken(chainId);
    const response = await getFormsRequest().post<APIResponse<string>>(
      '/users/sign',
      { signature },
    );

    return response.data;
  }
}

async function createEthereumSignedAuthToken(chainId: number) {
  const typedData = JSON.stringify({
    domain: {
      chainId,
      name: 'Form App',
      verifyingContract: '0xF24dd9292805A49A7Dd8EcDe9f9B0A1E952175B9',
      version: '1',
    },

    message: {
      prompt:
        'Welcome! In order to authenticate to this website, sign this request and your public address will be sent to the server in a verifiable way.',
      createdAt: `${Date.now()}`,
    },
    primaryType: 'AuthRequest',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      AuthRequest: [
        { name: 'prompt', type: 'string' },
        { name: 'createdAt', type: 'uint256' },
      ],
    },
  });

  const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
  });
  const from = accounts[0];
  const params = [from, typedData];
  const method = 'eth_signTypedData_v4';

  const signature = await window.ethereum.request({ method, params });

  return JSON.stringify({
    address: from,
    typedData: btoa(typedData),
    signature,
  });
}
