import { useIsMutating } from "@tanstack/react-query";
import { useThrottle } from "@uidotdev/usehooks";
import { Icon } from "./Icon";

export function MutationDetector() {
  const isMutating = useIsMutating();
  const throttledIsMutating = useThrottle(isMutating, 1500);

  return (
    <div className="relative">
      {throttledIsMutating > 0 && (
        <div className="flex gap-[8px] items-center absolute top-[calc(50%-14px)]">
          <Icon name="spinner" size={24} />
          <div className="text-[12px] font-[400] leading-[28px] text-[#605D66]">
            Saving...
          </div>
        </div>
      )}
    </div>
  );
}
