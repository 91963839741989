import { cn } from '@/lib/utils';

export function Steps() {
  return (
    <div className="flex flex-col gap-[42px] px-[16px] lg:px-[80px] bg-[url('/images/landing-steps-bg.svg')] pt-[30px] pb-[34px] bg-no-repeat bg-cover">
      <Step>
        <Step.Image
          src="/images/step-1.svg"
          className="order-last md:order-first"
        />
        <Step.Paragraph
          step={1}
          title="Create Your Custom Form"
          description="You can create custom forms with various question types, tailored to capture the exact data you need. These forms will automatically transform into datasets that provide valuable insights and allow you to query the data. The dataset will also be listed on our marketplace, where it can be sold to the public. Revenue is distributed between the form creator and the users who filled out the form."
        />
      </Step>
      <Step>
        <Step.Paragraph
          step={2}
          title="Share the Form and Gather Data"
          description="Once your form is ready, a unique link will be generated, making it easy to share with your users. Collect valuable insights from their responses. When the dataset is purchased, part of the revenue is shared with those who answered the form."
        />
        <Step.Image src="/images/step-2.svg" />
      </Step>
      <Step>
        <Step.Image
          src="/images/step-3.svg"
          className="order-last md:order-first"
        />
        <Step.Paragraph
          step={3}
          title="Earn Rewards"
          description="Both the form creator (the dataset owner) and the users who filled out the form receive rewards. This creates a win-win scenario for everyone involved."
        />
      </Step>
    </div>
  );
}

function Step({ children }: { children: React.ReactNode }) {
  return (
    <div className="grid gap-[32px] lg:gap-[50px] grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
      {children}
    </div>
  );
}

Step.Paragraph = function StepText({
  step,
  title,
  description,
}: { step: number; title: string; description: string }) {
  return (
    <div className="flex flex-col gap-[10px] items-start col-span-1 md:col-span-2 lg:col-span-3">
      <div className="text-[18px] leading-[22.5px] font-[500] text-[#D0BCFF]">
        Step {step}
      </div>
      <div className="text-[35px] leading-[135%] font-[400] text-[#fff] text-left">
        {title}
      </div>
      <div className="text-[#CAC5CD] text-[16px] leading-[140%] font-[500] text-left">
        {description}
      </div>
    </div>
  );
};

Step.Image = function StepImage({
  src,
  className,
}: { src: string; className?: string }) {
  return (
    <img
      src={src}
      alt=""
      className={cn('col-span-1 md:col-span-2', className)}
    />
  );
};
