import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';

export function CreateFormButton() {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate({ to: '/forms' })}>Go to dashboard</Button>
  );
}
