import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { Suspense } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import { generateId } from './utils';

const FADE_IN_OUT = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export function withFallback(
  Comp: React.ComponentType,
  LoaderFallback: React.ComponentType = DefaultLoaderFallback,
  ErrorFallback: React.ComponentType<FallbackProps> = DefaultErrorFallback,
) {
  const fallbackKey = ['fallback', generateId()];

  return () => {
    // Workaround to reset the error boundary when the fallback query changes.
    const fallbackQuery = useQuery({
      queryKey: fallbackKey,
      queryFn: () => [generateId()],
    });

    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={fallbackQuery.data ?? []}
      >
        <AnimatePresence mode="wait">
          <Suspense
            fallback={
              <motion.div {...FADE_IN_OUT} className="h-full">
                <LoaderFallback />
              </motion.div>
            }
          >
            <motion.div {...FADE_IN_OUT} className="h-full w-full">
              <Comp />
            </motion.div>
          </Suspense>
        </AnimatePresence>
      </ErrorBoundary>
    );
  };
}

export function DefaultErrorFallback() {
  return <div>Error</div>;
}

export function DefaultLoaderFallback() {
  return null;
}
