import type { PositionedField, Question } from '@/forms/domains/form';
import { buildSchema } from '@/lib/validateResponse';
import { useSubmitResponse } from '@/response/mutations/useSubmitResponse';
import type { FormDraftResponse } from '@/response/types';
import { useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';
import { z } from 'zod';
import { Icon } from './Icon';
import { Button } from './ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

export function ResponseSentDialog({
  formId,
  response,
  fields,
}: {
  formId: string;
  response: FormDraftResponse;
  fields: PositionedField<Question>[];
}) {
  const navigate = useNavigate();
  const submitResponse = useSubmitResponse({
    formId,
  });
  const schema = useMemo(() => z.object(buildSchema(fields)), [fields]);
  const reasonForDisabled = schema.safeParse(response).error?.issues[0];

  return (
    <Dialog>
      <DialogTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => submitResponse.mutateAsync(response)}
                disabled={!!reasonForDisabled}
              >
                Submit
              </Button>
            </TooltipTrigger>
            {!!reasonForDisabled && (
              <TooltipContent side="left">
                {getErrorMessage(reasonForDisabled, fields)}
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px] border-border p-0 pb-[48px]">
        <DialogHeader>
          <div className="flex items-center gap-[16px] border-b border-[#605D64] px-[64px] pt-[36px] pb-[16px]">
            <Icon size={24} name="party-popper" />
            <DialogTitle>Your response has been sent!</DialogTitle>
          </div>
          <DialogDescription className="pt-[28px] pb-[32px] px-[64px]">
            Thanks for your response. Your response has been saved
          </DialogDescription>
          <DialogFooter
            omitSeparator
            className="px-[64px]"
            classNameContent="w-full"
          >
            <div className="flex justify-end w-full">
              <DialogClose asChild>
                <Button
                  variant="outline"
                  onClick={() => {
                    navigate({
                      to: '/forms/$id',
                      params: { id: formId },
                      search: { page: 1 },
                    });
                  }}
                >
                  New response
                </Button>
              </DialogClose>
            </div>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );

  function getErrorMessage(issue: z.ZodIssue, questions: Question[]): string {
    if (issue.path.length === 0) return issue.message;
    const questionId = issue.path[0];
    const question = questions.find((q) => q.id === questionId);

    return `Invalid field #${question?.order}: ${issue.message}`;
  }
}
