import { useQuestions } from "../queries/useQuestions";

export function useQuestion({
  formId,
  questionId,
}: { formId: string; questionId: string }) {
  const questions = useQuestions({ formId });

  return questions.data?.find((question) => question.id === questionId);
}
