import { Icon } from '@/components/Icon';
import { Button } from '@/components/ui/button';
import type { Form } from '@/forms/domains/form';
import { useFormStats } from '@/forms/queries/useFormStats';
import { useQuestions } from '@/forms/queries/useQuestions';
import { useDataset } from '@/lib/hooks/useDataset';
import { cn, formatDate, openTab } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { FormItemContextMenu } from './FormItemContextMenu';

export function FormListItem({ form }: { form: Form }) {
  const dataset = useDataset({ id: form.datasetId });
  const questions = useQuestions({ formId: form.id });
  const formStats = useFormStats({ formId: form.id });
  const navigate = useNavigate();
  const isPublished = form.status === 'published';
  const questionsCounter = questions.data?.length || 0;

  return (
    <tr className="group transition-all">
      <td className="py-[10px] pl-[16px] flex gap-[16px] bg-[#212226] rounded-l-[8px]">
        <Avatar active={isPublished} />
        <div className="flex flex-col">
          <div className="flex gap-[8px]">
            <div className="text-[16px] font-[700] leading-[24px] text-[#CAC5CD] text-left">
              {form.title}
              {dataset.data && ','}
            </div>
            <div className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#938F96]">
              {dataset.data?.title}
            </div>
          </div>
          <div className="text-[14px] font-[400] leading-[20px] tracking-[.25px] flex gap-[10px]">
            <div className="text-[#605D64]">
              Created: {formatDate(form.createdAt, 'll')}
            </div>
            {isPublished && (
              <div className="text-[#8DEFB4] text-[14px] font-[400] leading-[20px] tracking-[.25px]">
                Published
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="py-[10px] text-left bg-[#212226]">
        <Button
          variant="text"
          className="px-[24px] rounded-[4px] text-[#CAC5CD] leading-[16px] bg-[#2A2B2F] hover:bg-[#2A2B2F] active:bg-[#2A2B2F] invisible group-hover:visible"
          onClick={() =>
            navigate({
              to: '/forms/$id/$mode',
              params: { id: form.id, mode: 'edit' },
            })
          }
        >
          Edit
        </Button>
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {questionsCounter}
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {formStats.data?.total_responses || 0}
      </td>
      <td className="py-[10px] text-left text-[#CAC5CD] text-[14px] font-[300] leading-[20px] tracking-[.25px] bg-[#212226]">
        {formatDate(form.updatedAt, 'll')}
      </td>
      <td className="py-[10px] text-left bg-[#212226]">
        <DatasetLinkButton datasetId={dataset?.data?.id} />
      </td>
      <td className="py-[10px] pr-[16px] text-left bg-[#212226] rounded-r-[8px]">
        <FormItemContextMenu form={form} stats={formStats.data} />
      </td>
    </tr>
  );
}

function DatasetLinkButton({ datasetId }: { datasetId?: string }) {
  return (
    <Button
      variant="text"
      className="group/dataset-link-btn gap-[8px] enabled:hover:gap-[12px] bg-[#121212] hover:!bg-[#121212] active:!bg-[#121212] h-auto pl-[12px] pr-[4px] py-[4px] rounded-[28px] transition-all"
      onClick={() =>
        openTab(`${import.meta.env.VITE_APP_NUKLAI_URL}/dataset/${datasetId}`)
      }
      disabled={!datasetId}
    >
      <Icon name="nuklai" size={15} />
      <Icon
        name="angle-right"
        size={15}
        color="#D0BCFF"
        className={cn(
          'bg-[#2A2B2F] rounded-[24px] p-[6px]',
          datasetId && 'group-hover/dataset-link-btn:bg-[#36343B]',
        )}
      />
    </Button>
  );
}

function Avatar({ active }: { active: boolean }) {
  return (
    <div className="relative bg-white rounded-[8px] w-[41px] h-[41px]">
      &nbsp;
      {active && (
        <div className="absolute -right-[2px] -top-[3px] w-[10px] h-[10px] rounded-full bg-[#8DEFB4]">
          &nbsp;
        </div>
      )}
    </div>
  );
}
