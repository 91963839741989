import { useUpdateForm } from '@/forms/mutations/useUpdateForm';
import { useForm } from '@/forms/queries/useForm';
import { useQuestions } from '@/forms/queries/useQuestions';
import { getDataQuestions } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';
import { Icon } from './Icon';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { TextField } from './ui/textfield';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

export function FormPublishDialog({ formId }: { formId: string }) {
  const navigate = useNavigate();
  const updateForm = useUpdateForm({ formId });
  const form = useForm({ id: formId });
  const questions = useQuestions({ formId });

  if (!form.data) return null;

  const reasonForDisabled = getReasonForDisabled();

  return (
    <Dialog>
      <DialogTrigger>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => updateForm.mutateAsync({ status: 'published' })}
                disabled={!!reasonForDisabled}
              >
                Publish
              </Button>
            </TooltipTrigger>
            {!!reasonForDisabled && (
              <TooltipContent side="left">{reasonForDisabled}</TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px] border-border p-0 pb-[48px]">
        <DialogHeader>
          <div className="flex items-center gap-[16px] border-b border-[#605D64] px-[64px] pt-[36px] pb-[16px]">
            <Icon size={24} name="party-popper" />
            <DialogTitle>Your Form has been saved on our platform!</DialogTitle>
          </div>
          <DialogDescription className="pt-[28px] pb-[32px] px-[64px]">
            <div className="pb-[10px]">
              Thanks for creating a form. Your form has been saved and is ready
              to share. Please find the share link below and don't forget to
              share it on your socials.
            </div>
            <div className="flex gap-[8px]">
              <div className="w-full">
                <TextField
                  value={
                    `${window.location.origin}/forms/${formId}` /** TODO: implement type safe solution */
                  }
                />
              </div>
              <div className="flex items-start">
                <Button
                  onClick={() =>
                    navigate({ to: '/forms/$id', params: { id: formId } })
                  }
                  className="rounded-[8px]"
                >
                  Open form
                </Button>
              </div>
            </div>
          </DialogDescription>
          <DialogFooter omitSeparator className="px-[64px]">
            <Button onClick={() => navigate({ to: '/' })} variant="outline">
              Back
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );

  function getReasonForDisabled() {
    const dataQuestions = getDataQuestions(questions.data ?? []);
    if (form.data?.status === 'published') return 'Form is already published';
    if (dataQuestions.length === 0) return 'Form is empty';
    if (dataQuestions.find((question) => !question.schemaField))
      return 'Form has some empty schema fields';
  }
}
