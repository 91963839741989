import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';
import { Icon } from '../Icon';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-[8px] whitespace-nowrap font-[500] py-[10px] px-[24px] transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:text-[#E6E0E9]',
  {
    variants: {
      variant: {
        filled:
          'text-[#381E72] bg-[#D0BCFF] rounded-[100px] transition-shadow hover:bg-[#B9A3EA] hover:shadow-[0_1px_3px_1px_rgba(0,0,0,0.15),0_1px_2px_0_rgba(0,0,0,0.30)] active:bg-[#AE98DF] focus-visible:bg-[#AE98DF] disabled:bg-[#E6E0E9]/[.12]',
        outline:
          'text-[#D0BCFF] border border-[#938F99] rounded-[100px] hover:bg-[#D0BCFF]/[.12] active:bg-[#D0BCFF]/[.12] active:border-[#D0BCFF] focus-visible:bg-[#D0BCFF]/[.16] disabled:border-[#E6E0E9]/[.12]',
        text: 'text-[#D0BCFF] px-[12px] rounded-[100px] hover:bg-[#D0BCFF]/[.08] active:bg-[#D0BCFF]/[.12] focus-visible:bg-[#D0BCFF]/[.12] disabled:text-[#E6E0E9]',
      },
      size: {
        medium: 'text-[14px] leading-[20px]',
        large: 'text-[16px] leading-[24px] tracking-[.5px]',
      },
    },
    defaultVariants: {
      variant: 'filled',
      size: 'medium',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, icon, asChild = false, children, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          icon && 'pl-[16px]',
        )}
        ref={ref}
        {...props}
      >
        {icon && <Icon name={icon} size={18} color={getIconColor()} />}
        {children}
      </Comp>
    );

    function getIconColor() {
      if (props.disabled) return '#E6E0E9';
      if (!variant) return undefined;
      if (variant === 'filled') return '#381E72';
      return '#D0BCFF';
    }
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
