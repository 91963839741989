export function ComingSoon() {
  return (
    <section className="flex flex-col gap-[27px] px-[16px] lg:px-[80px] pb-[32px] pt-[32px]">
      <div className="text-[#D0BCFF] text-[40px] leading-[135%] font-[400] text-left">
        <span className="text-[#fff]">Coming</span> Soon
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[20px]">
        <FeatureCard
          title="Automated Dataset Generation"
          description="Datasets will be automatically generated based on user responses, offering immediate insights."
          icon="/images/automated-data-generation.svg"
        />
        <FeatureCard
          title="Advanced Querying"
          description="Datasets will be queryable, giving both the creator and buyers the ability to pinpoint the exact data they need."
          icon="/images/advanced-querying.svg"
        />
        <FeatureCard
          title="Cross-Dataset Comparisons"
          description="Query data across multiple datasets, enabling enhanced insights by comparing different datasets available on the marketplace."
          icon="/images/cross-dataset-comparisons.svg"
        />
        <FeatureCard
          title="Revenue Sharing"
          description="When a dataset is sold, rewards will be distributed between the form creator and the users who responded."
          icon="/images/revenue-sharing.svg"
        />
      </div>
    </section>
  );
}

interface FeatureCardProps {
  title: string;
  description: string;
  icon: string;
}

function FeatureCard(props: FeatureCardProps) {
  return (
    <div className="border border-[#22212F] bg-white/[0.02] rounded-[8px] flex flex-col gap-[23px]">
      <div className="flex flex-col gap-[17px] px-[15px] lg:px-[30px] pt-[15px] lg:pt-[30px]">
        <div className="text-left text-[#D0BCFF] text-[22px] leading-[28.8px]">
          {props.title}
        </div>
        <div className="text-left text-[16px] leading-[23.2px] text-[#FFFFFF]">
          {props.description}
        </div>
      </div>
      <div className="py-[20px] lg:py-[40px] flex justify-center">
        <img src={props.icon} alt="" />
      </div>
    </div>
  );
}
