import type {
  Form,
  ListOption,
  PositionedField,
  SingleChoiceListItem,
} from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import { generateId } from '@/lib/utils';
import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import debounce from 'lodash.debounce';
import { useMemo, useState } from 'react';
import { getContentDefinition } from '../AddFieldDialog/questionDefinition';
import { Icon } from '../Icon';
import { ListButton } from '../ListButton';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { EditableFieldWrapper } from './EditableFieldWrapper';
import { ReadableFieldWrapper } from './ReadableFieldWrapper';

export function SingleChoiceField({
  field,
  form,
}: {
  field: PositionedField<SingleChoiceListItem>;
  form: Form;
}) {
  const updateQuestion = useUpdateQuestion({
    formId: form.id,
  });
  const questionDefinition = getContentDefinition('short_text');
  const [updatedList, setUpdatedList] = useState(
    field.options.listOptions ?? [],
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce((val: ListOption[]) => {
        updateQuestion.mutateAsync({
          questionId: field.id,
          options: {
            ...field.options,
            listOptions: val,
          },
        });
      }, 750),
    [updateQuestion.mutateAsync, field.options, field.id],
  );

  if (!questionDefinition) return null;

  return (
    <EditableFieldWrapper field={field}>
      <div className="flex flex-col gap-[32px]">
        <div className="flex flex-col items-start">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={updatedList}>
              <RadioGroup className="w-full">
                {updatedList.map((option) => (
                  <ChoiceItem
                    key={option.id}
                    option={option}
                    updateList={updateList}
                    deleteItem={() => removeItem(option.id)}
                    deletable={updatedList.length > 1}
                  />
                ))}
              </RadioGroup>
            </SortableContext>
          </DndContext>
        </div>
        <div className="flex gap-[24px]">
          <ListButton label="Add choice" onClick={addItem} />
          <ListButton label="Add “Other” Choice" onClick={addItem} />
        </div>
      </div>
    </EditableFieldWrapper>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setUpdatedList((items) => {
        const oldIndex = items.findIndex((option) => option.id === active.id);
        const newIndex = items.findIndex((option) => option.id === over.id);

        const updatedList = arrayMove(items, oldIndex, newIndex);

        debouncedUpdate(updatedList);

        return updatedList;
      });
    }
  }

  function updateList(option: ListOption, value: string) {
    setUpdatedList((prev) => {
      const updatedValue = prev.map((prevOption) => {
        if (prevOption.id === option.id) {
          return { ...prevOption, label: value };
        }
        return prevOption;
      });

      debouncedUpdate(updatedValue);

      return updatedValue;
    });
  }

  function addItem() {
    setUpdatedList((prev) => {
      const newOption = {
        id: generateId(),
        label: 'Option',
      };

      const updatedValue = [...prev, newOption];

      debouncedUpdate(updatedValue);

      return updatedValue;
    });
  }

  function removeItem(id: string) {
    setUpdatedList((prev) => {
      const updatedValue = prev.filter((option) => option.id !== id);

      debouncedUpdate(updatedValue);

      return updatedValue;
    });
  }
}

function ChoiceItem({
  option,
  updateList,
  deleteItem,
  deletable,
}: {
  option: ListOption;
  updateList: (option: ListOption, value: string) => void;
  deleteItem: () => void;
  deletable: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: option.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="flex items-center gap-[15px] group w-full relative py-[12px]"
      ref={setNodeRef}
      style={style}
    >
      <div
        className="opacity-0 group-hover:!opacity-100 transition-opacity absolute -left-[15px] flex gap-[1px] cursor-move"
        {...attributes}
        {...listeners}
      >
        <Icon name="dots-vertical" width={4} height={16} />
        <Icon name="dots-vertical" width={4} height={16} />
      </div>
      <RadioGroupItem
        id={option.id}
        value={option.id}
        className="min-w-[20px] h-[20px]"
      />
      <label
        htmlFor={option.id}
        className="cursor-pointer flex items-center justify-between w-full"
      >
        <input
          value={option.label}
          className="bg-transparent text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] outline-none w-full focus-within:!text-[#E6E0E9] caret-[#D0BCFF]"
          onChange={({ target: { value } }) => {
            updateList(option, value);
          }}
        />
        {deletable && (
          <Icon
            width={14}
            height={16}
            name="bin"
            className="opacity-0 group-hover:!opacity-100 transition-opacity"
            onClick={deleteItem}
          />
        )}
      </label>
    </div>
  );
}

export function ClientSingleChoiceField({
  field,
  response,
  onChange,
}: {
  field: PositionedField<SingleChoiceListItem>;
  response?: string;
  onChange?: (value: string) => void;
}) {
  return (
    <ReadableFieldWrapper field={field}>
      <div className="flex flex-col gap-[32px]">
        <div className="flex flex-col items-start">
          <RadioGroup
            value={response}
            onValueChange={(value) => onChange?.(value)}
          >
            {field.options.listOptions?.map((option) => (
              <ClientChoiceItem key={option.id} option={option} />
            ))}
          </RadioGroup>
        </div>
      </div>
    </ReadableFieldWrapper>
  );
}

function ClientChoiceItem({
  option,
}: {
  option: ListOption;
}) {
  return (
    <div className="flex items-center gap-[15px] w-full relative py-[12px]">
      <RadioGroupItem
        id={option.id}
        value={option.id}
        className="min-w-[20px] h-[20px]"
      />
      <label
        htmlFor={option.id}
        className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] cursor-pointer flex justify-between w-full"
      >
        {option.label}
      </label>
    </div>
  );
}
