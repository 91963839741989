import { cn } from '@/lib/utils';
import { twMerge } from 'tailwind-merge';
import { Icon } from './Icon';
import { TextField } from './ui/textfield';

interface SearchFormInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export function SearchFormInput({
  value,
  onChange,
  className,
}: SearchFormInputProps) {
  return (
    <div
      className={twMerge(
        'group flex items-center relative [&_.search-icon_.mask]:hover:!bg-[#E6E0E9] [&_input]:placeholder:hover:!text-[#E6E0E9]',
        value &&
          '[&_.search-icon.mask]:!bg-[#E6E0E9] [&_input]:!text-[#E6E0E9]',
        className,
      )}
    >
      <TextField
        id="search-forms"
        placeholder="Find"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="rounded-[8px] bg-[#121212] border-none placeholder:text-[#938F96] px-[52px] h-[46px] focus-visible:!px-[52px] !transition-colors"
      />
      <Icon
        name="search"
        size={24}
        color="#938F96"
        className="search-icon absolute search-icon p-[16px] pointer-events-none [&_.mask]:transition-colors"
      />
      <Icon
        name="close"
        size={24}
        color="#938F96"
        className={cn(
          'absolute p-[16px] right-[10px] cursor-pointer opacity-0 transition-opacity [&_.mask]:transition-colors [&_.mask]:hover:!bg-[#E6E0E9]',
          value && 'opacity-100',
        )}
        onClick={() => onChange('')}
      />
    </div>
  );
}
