import { Navbar } from '@/components/Navbar/Navbar';
import { Outlet } from '@tanstack/react-router';

export function RootView() {
  return (
    <div className="flex flex-col w-full h-[100vh] items-center">
      <div className="w-full">
        <Navbar />
      </div>
      <div className="flex justify-center w-full h-full max-w-[1440px]">
        <Outlet />
      </div>
    </div>
  );
}
