import { cn } from "@/lib/utils";

export interface IconProps {
  color?: string;
  name?: string;
  src?: string;
  size?: number;
  width?: number;
  height?: number;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const isHexColor = /^#([0-9A-F]{3}){1,2}$/i;

export const Icon = (props: IconProps) => {
  const { color, name, src, size, width, height, className, onClick } = props;

  const url = src || `/icons/${name}.svg`;

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div className={cn(className, onClick && "pointer")} onClick={onClick}>
      {color ? (
        <div
          className="mask"
          style={{
            WebkitMaskImage: `url('${url}')`,
            WebkitAlignItems: "center",
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskSize: "100%",
            mask: `url('${url}') center center/100% 100% no-repeat`,
            background: parseColor(color),
            width: `${size ?? width}px`,
            minWidth: `${size ?? width}px`,
            height: `${size ?? height}px`,
            minHeight: `${size ?? height}px`,
          }}
        />
      ) : (
        <img
          src={`${url}`}
          alt=""
          style={{
            width: `${size ?? width}px`,
            minWidth: `${size ?? width}px`,
            height: `${size ?? height}px`,
          }}
        />
      )}
    </div>
  );

  function parseColor(color: string) {
    if (isHexColor.test(color)) return color;

    return `var(--${color})`;
  }
};
