export function Footer() {
  return (
    <div className="h-[314px] flex flex-col gap-[48px] justify-center items-center pt-[92px] pb-[86px]">
      <div>
        <img
          src="/images/nuklai-logo.svg"
          alt="Nuklai"
          width={43}
          height={62}
        />
      </div>
      <div className="flex justify-between gap-[48px] max-w-[350px] w-full">
        <a href="mailto:hello@nukl.ai">Contact</a>
        <div>Terms</div>
      </div>
    </div>
  );
}
