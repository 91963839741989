import { CreateFormButton } from './CreateFormButton';

export function HeroSection() {
  return (
    <section className="flex gap-[50px] px-[16px] lg:pl-[80px] lg:pr-0 pb-[48px] bg-[url('/images/landing-hero-mobile-bg.svg')] md:bg-none bg-no-repeat bg-center bg-cover pt-[87px] lg:pt-0 items-start">
      <div className="flex-[1] flex flex-col gap-[48px] items-start lg:pt-[69px]">
        <div className="flex flex-col gap-[32px]">
          <div className="text-[#D0BCFF] text-[30px] md:text-[38px]  lg:text-[48px] leading-[135%] font-[400] text-center md:text-left">
            <span className="text-[#fff]">Get Data Insights</span> While
            Rewarding Community
          </div>
          <div className="text-[17px] lg:text-[20px] leading-[140%] tracking-[0.25px] text-[#CAC5CD] text-center md:text-left">
            Our platform enables you to gather the data you need from users,
            while rewarding them in return. All data will be queryable and
            comparable with other datasets, making it both versatile and
            valuable.
          </div>
        </div>
        <div className="flex justify-center md:justify-start w-full">
          <CreateFormButton />
        </div>
      </div>
      <div className="flex-[1] hidden md:flex">
        <img src="/images/landing-hero-bg.svg" alt="" />
      </div>
    </section>
  );
}
