import { Button } from './ui/button';

export function ListButton({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) {
  return (
    <Button
      variant="text"
      icon="add"
      onClick={onClick}
      className="rounded-[6px] !px-[12px] py-[6px] hover:bg-[#212226] font-[400] tracking-[.25px]"
    >
      {label}
    </Button>
  );
}
