interface Position {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export function scrollToElement(
  container: HTMLElement,
  target: HTMLElement,
  duration: number,
) {
  const pos = getRelativePos(target);
  scrollTo(container, pos.top, duration / 1000);
}

export function scrollToBottom(container: HTMLElement, duration: number) {
  scrollTo(container, container.scrollHeight, duration / 1000);
}

function getRelativePos(elm: HTMLElement): Position {
  const pPos = elm.parentElement?.getBoundingClientRect();
  const cPos = elm.getBoundingClientRect();
  const pos: Position = { top: 0, right: 0, bottom: 0, left: 0 };

  if (!pPos) return pos;

  pos.top = cPos.top - pPos.top + (elm.parentElement?.scrollTop ?? 0);
  pos.right = cPos.right - pPos.right;
  pos.bottom = cPos.bottom - pPos.bottom;
  pos.left = cPos.left - pPos.left;

  return pos;
}

function scrollTo(element: HTMLElement, to: number, duration: number) {
  const start = element.scrollTop;
  const change = to - start;
  const startTime = performance.now();

  function animateScroll() {
    const now = performance.now();
    const elapsed = (now - startTime) / 1000;
    const t = elapsed / duration;

    element.scrollTop = start + change * easeInOutQuad(t);

    if (t < 1) window.requestAnimationFrame(animateScroll);
  }

  animateScroll();
}

function easeInOutQuad(t: number) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}
