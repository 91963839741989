import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useMutation } from '@tanstack/react-query';
import type { Form } from '../domains/form';

export function useCreateForm() {
  return useMutation({
    mutationKey: ['createForm'],
    mutationFn,
  });

  async function mutationFn(form: {
    title: string;
    description: string;
  }) {
    const response = await getFormsRequest().post<APIResponse<Form>>(
      '/forms',
      form,
    );

    return response.data.data;
  }
}
