import { useRouterState, useNavigate } from "@tanstack/react-router";
import { useBreadcrumbs } from "@/lib/hooks/useBreadcrumbs";
import { cn } from "@/lib/utils";
import { Icon } from "./Icon";

export function Breadcrumbs() {
  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumbs();
  const router = useRouterState();
  const activeRoute = router.location.pathname;

  return (
    <div className="flex items-center gap-[8px]">
      {breadcrumbs.map((tab, index) => (
        <div key={tab.title} className="flex gap-[8px] items-center">
          {index !== 0 && <Icon name="angle-right" width={10} height={16} />}
          {/* biome-ignore lint/a11y/useButtonType: <explanation> */}
          <button
            onClick={() => navigate({ to: tab.path })}
            className={cn(
              activeRoute === tab.path
                ? "text-[#FFFFFF]"
                : "text-[#B6B6B6] hover:text-[#FFFFFF]",
              "text-[14px] font-[400] leading-[20px] tracking-[.1px] transition-color",
            )}
          >
            {tab.title}
          </button>
        </div>
      ))}
    </div>
  );
}
