import { useQuery } from "@tanstack/react-query";
import { type APIResponse, getFormsRequest } from "../../lib/request";
import type { Form } from "@/forms/domains/form";

export function useForm({ id }: { id: string }) {
  return useQuery({
    queryKey: ["form", id],
    queryFn,
  });

  async function queryFn() {
    const response = await getFormsRequest().get<APIResponse<Form>>(
      `/forms/${id}`,
    );

    return response.data.data;
  }
}
