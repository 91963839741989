import type { PositionedField, Question } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import type { ReactNode } from 'react';
import { getContentDefinition } from '../AddFieldDialog/questionDefinition';
import { Icon } from '../Icon';
import { FormEditableDescription } from '../QuestionEditableDescription';
import { QuestionEditableTitle } from '../QuestionEditableTitle';

export function EditableFieldWrapper({
  children,
  field,
}: {
  children: ReactNode;
  field: PositionedField<Question>;
}) {
  const questionDefinition = getContentDefinition(field.type);
  const updateQuestion = useUpdateQuestion({
    formId: field.formId,
  });

  if (!questionDefinition) return null;

  return (
    <div className="px-[36px] flex flex-col gap-[16px]">
      <div className="flex w-full gap-[8px] items-start">
        <div className="flex items-center gap-[8px] pt-[8px]">
          <div
            className="text-[16px] font-[600] leading-[28px]"
            style={{ color: questionDefinition.backgroundColor }}
          >
            {field.order}
          </div>
          <Icon
            name="arrow-right"
            color={questionDefinition.backgroundColor}
            width={14}
            height={16}
          />
        </div>
        <QuestionEditableTitle
          value={field.title ?? 'Your question here.'}
          onChange={(value) =>
            updateQuestion.mutateAsync({
              questionId: field.id,
              title: value,
            })
          }
        />
      </div>
      <div className="flex flex-col gap-[16px] pl-[40px]">
        <FormEditableDescription
          value={field.description ?? ''}
          onChange={(value) =>
            updateQuestion.mutateAsync({
              questionId: field.id,
              description: value,
            })
          }
        />
        <div className="pl-[16px]">{children}</div>
      </div>
    </div>
  );
}
