import type { Form } from '@/forms/domains/form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { type APIResponse, getFormsRequest } from '../../lib/request';

export function usePublicForm({ id }: { id: string }) {
  return useSuspenseQuery({
    queryKey: ['publicForm', id],
    queryFn,
  });

  async function queryFn() {
    const response = await getFormsRequest().get<APIResponse<Form>>(
      `/public/forms/${id}`,
    );

    return response.data.data;
  }
}
