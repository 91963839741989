import { useState, type ReactNode } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useUpdateForm } from "@/forms/mutations/useUpdateForm";
import { TextField } from "./ui/textfield";
import { useForm } from "@/forms/queries/useForm";
import { Icon } from "./Icon";

export function EditFormTitle({
  children,
  formId,
}: {
  children: ReactNode;
  formId: string;
}) {
  const form = useForm({ id: formId });
  const [title, setTitle] = useState(form.data?.title ?? "");
  const updateForm = useUpdateForm({ formId });

  if (!form.data) return null;

  return (
    <Dialog>
      <DialogTrigger asChild onClick={() => setTitle(form.data.title)}>
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex gap-[16px] items-center">
            <div className="bg-[#D0BCFF] rounded-[8px] p-[20px]">
              <Icon name="pen-to-square" size={16} color="#121212" />
            </div>
            <DialogTitle>Rename this form</DialogTitle>
          </div>
        </DialogHeader>
        <div className="pt-[30px] pb-[24px]">
          <TextField
            value={title}
            placeholder="_Name of Form_"
            onChange={({ target: { value } }) => setTitle(value)}
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              disabled={
                updateForm.isPending || form.data.title === title || !title
              }
              type="submit"
              onClick={() => updateForm.mutateAsync({ title: title })}
            >
              {updateForm.isPending && (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  &nbsp;
                </>
              )}
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
