import debounce from 'lodash.debounce';

import type { Question } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import { useForm } from '@/forms/queries/useForm';
import { useQuestions } from '@/forms/queries/useQuestions';
import {
  calculatePageIndicator,
  calculateQuestionIndicator,
  errorMessageOf,
} from '@/lib/utils';
import { useMemo, useState } from 'react';
import { getContentDefinition } from './AddFieldDialog/questionDefinition';
import { Icon } from './Icon';
import { QuestionIndicator } from './QuestionIndicator';
import { TextField } from './ui/textfield';

type QuestionItemProps = Pick<
  Question,
  'id' | 'formId' | 'schemaField' | 'title' | 'type' | 'order'
>;

const exampleQuestion: Question = {
  id: '2',
  formId: '1',
  title: 'How old is the average person in Europe?',
  type: 'short_text',
  schemaField: 'Average age Europe',
  createdAt: '2021-09-29T00:00:00Z',
  updatedAt: '2021-09-29T00:00:00Z',
  deletedAt: '2021-09-29T00:00:00Z',
  order: 1,
  description: '',
  options: { required: false, supportingText: '' },
};

export function Schema({ formId }: { formId: string }) {
  const questions = useQuestions({ formId });
  const form = useForm({ id: formId });

  if (!questions.data || !form.data) return null;

  return (
    <div className="h-full flex gap-[24px] flex-col">
      <SchemaHeader />
      <div className="flex px-[36px]">
        <div className="flex flex-col gap-[24px] w-full">
          {questions.data.map((question) => (
            <QuestionItem
              key={question.id}
              question={question}
              reasonForDisabled={getReasonForDisabled()}
              indicator={
                question.type === 'new_page'
                  ? calculatePageIndicator(question.id, questions.data)
                  : calculateQuestionIndicator(question.id, questions.data)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );

  function getReasonForDisabled() {
    if (form.data?.status === 'published') {
      return 'Cannot edit schema of a published form';
    }
  }
}

function SchemaHeader() {
  return (
    <div className="flex flex-col gap-[32px] bg-[#18181C] px-[36px] pb-[24px] pt-[36px]">
      <div className="flex gap-[23px]">
        <HeaderIcon />
        <div className="text-[#CAC5CD] text-[16px] font-[300] leading-[24px] tracking-[.5px] text-left">
          As the questions will be stored in a{' '}
          <span className="italic font-[400]">dataset</span>, a{' '}
          <span className="text-[#D0BCFF] font-[700]">"field name"</span> is
          required. Please give an informative description of the question using
          1 to 3 words, describing what the data is used for.
          <br />
          <br /> Field names cannot contain special characters and cannot start
          with a digit. Example of a valid field name: abc123-def456
        </div>
      </div>
      <div className="flex flex-col gap-[4px]">
        <div className="text-left text-[16px] italic leading-[24px] tracking-[.5px] font-[300] text-[#CAC5CD]">
          Example:
        </div>
        <QuestionItem question={exampleQuestion} indicator={1} />
      </div>
    </div>
  );
}

function HeaderIcon() {
  return (
    <div>
      <div className="p-[20px] rounded-[8px] bg-[#D0BCFF]">
        <Icon name="memo-circle-info" width={18} height={16} />
      </div>
    </div>
  );
}

function QuestionItem({
  question,
  reasonForDisabled,
  indicator,
}: {
  question: QuestionItemProps;
  indicator: number;
  reasonForDisabled?: string;
}) {
  const questionDefinition = getContentDefinition(question.type);
  const [fieldName, setFieldName] = useState(question.schemaField);
  const updateQuestion = useUpdateQuestion({
    formId: question.formId,
  });

  const debouncedUpdate = useMemo(
    () =>
      debounce((val) => {
        updateQuestion.mutateAsync({
          questionId: question.id,
          schemaField: val,
        });
      }, 750),
    [updateQuestion.mutateAsync, question.id],
  );

  const errorMessage = errorMessageOf(updateQuestion.error);

  if (!questionDefinition) return null;

  if (question.type === 'new_page') {
    return <NewPageSchemaItem indicator={indicator} />;
  }

  return (
    <div className="flex gap-[16px]" id={question.id}>
      <div className="w-1/2 flex">
        <div className="w-full text-left text-[16px] font-[600] leading-[28px] italic border border-[#322F37] bg-[#121212] rounded-[8px] px-[24px] py-[8px]">
          {question.title}
        </div>
      </div>
      <div className="flex gap-[12px] w-1/2 items-end">
        <div className="flex items-center gap-[12px] w-full">
          <QuestionIndicator
            definition={questionDefinition}
            order={indicator}
            className="my-[12px]"
          />
          <Icon name="arrow-right-long" width={24} height={8} />
          <div className="max-w-[240px] w-full">
            <TextField
              value={fieldName}
              disabled={!!reasonForDisabled}
              title={reasonForDisabled}
              onChange={({ target: { value } }) => {
                setFieldName(value);
                debouncedUpdate(value);
              }}
            />
            {errorMessage && (
              <div className="text-[#FF0000] text-[12px] mt-[4px]">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function NewPageSchemaItem({ indicator }: { indicator: number }) {
  return (
    <div className="flex w-full justify-center">
      <div className="max-w-[400px] w-full relative flex justify-center">
        <div className="bg-[#2B2930] rounded-[52px] text-[#CAC5CD] text-[14px] leading-[20px] tracking-[0.25px] font-[300] px-[16px] py-[2px] z-10">
          page {indicator}
        </div>
        <div className="h-[1px] bg-[#2B2930] absolute top-1/2 left-0 w-full" />
      </div>
    </div>
  );
}
