import { useMyForms } from '@/forms/queries/useMyForms';
import { onKeyboardAction } from '@/lib/utils';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { twMerge } from 'tailwind-merge';
import { Icon } from './Icon';
import { SearchFormInput } from './SearchFormInput';
import { Separator } from './ui/separator';

interface SidebarLeftProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export function SidebarLeft({ searchValue, onSearchChange }: SidebarLeftProps) {
  const forms = useMyForms();

  return (
    <div className="sticky w-full h-full max-w-[245px] bg-[#212226] flex flex-col gap-[16px]">
      <SearchFormInput
        value={searchValue}
        onChange={onSearchChange}
        className="pl-[24px] pr-[12px] py-[16px]"
      />
      <SidebarItem
        id="workspace"
        title="My workspace"
        icon="user-filled"
        count={forms.data?.length ?? 0}
        className="py-[11px] px-[24px]"
      />

      <div className="px-[16px]">
        <Separator className="bg-[#383838]" />
      </div>

      <SidebarItem
        id="drafts"
        title="Drafts"
        count={getDraftFormsCounter()}
        className="py-[11px] px-[24px]"
      />
    </div>
  );

  function getDraftFormsCounter(): number {
    if (!forms.data) return 0;
    return forms.data.filter((form) => form.status === 'draft').length;
  }
}

interface SidebarItemProps {
  id: string;
  title: string;
  count?: number;
  icon?: string;
  className?: string;
}

function SidebarItem({ id, title, icon, count, className }: SidebarItemProps) {
  const navigate = useNavigate();
  const view = useSearch({
    from: '/forms',
    select: (search) => search.view,
  });

  return (
    <div
      className={twMerge(
        'flex items-center justify-between cursor-pointer hover:bg-[#2A2B2F] transition-colors',
        ((id === 'workspace' && !view) || view === id) && 'bg-[#2A2B2F]',
        className,
      )}
      onClick={onClick}
      onKeyDown={onKeyboardAction(onClick)}
      // biome-ignore lint/a11y/noNoninteractiveTabindex: <explanation>
      tabIndex={0}
    >
      <div className="flex items-center gap-[12px]">
        {icon && <Icon name={icon} size={16} color="#FFF" />}
        <div className="text-[#B6B6B6] text-[14px] leading-[20px] font-[400]">
          {title}
        </div>
      </div>
      {count !== undefined && (
        <div className="text-[#79747E] text-[14px] leading-[20px] font-[400]">
          {count}
        </div>
      )}
    </div>
  );

  function onClick() {
    navigate({
      to: '/forms',
      search: { view: id !== 'workspace' ? id : undefined },
    });
  }
}
