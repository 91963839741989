import { useMatchRoute } from '@tanstack/react-router';

export function usePublicRoute() {
  const matchRoute = useMatchRoute();

  return matchRoute({ to: '/forms/$id' });
}

export function useLandingRoute() {
  const matchRoute = useMatchRoute();

  return matchRoute({ to: '/' });
}
