import { useQuery } from '@tanstack/react-query';
import { getNuklaiPrivateRequest } from '../request';

export function useDataset({ id }: { id: string }) {
  return useQuery({
    queryKey: ['dataset', id],
    queryFn,
    enabled: !!id,
  });

  async function queryFn() {
    const result = await getNuklaiPrivateRequest().get(`/datasets/${id}`);

    return result.data;
  }
}
