import { useMutation } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';

interface UpdateDraftResponseProps {
  formId: string;
}

export function useUpdateDraftResponse({ formId }: UpdateDraftResponseProps) {
  const [, setDraftResponse] = useLocalStorage<Record<string, string | number>>(
    `form-${formId}-draft`,
    {},
  );

  return useMutation({
    mutationKey: ['updateDraftResponse'],
    mutationFn,
  });

  async function mutationFn({
    questionId,
    value,
  }: {
    questionId: string;
    value: string | number;
  }) {
    setDraftResponse((prev) => {
      if (value === '') {
        const { [questionId]: _, ...rest } = prev;
        return rest;
      }

      return { ...prev, [questionId]: value };
    });
  }
}
