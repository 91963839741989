import { FormPreview } from '@/components/FormPreview';
import { ResponseSentDialog } from '@/components/ResponseSentDialog';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import type { PositionedField, Question } from '@/forms/domains/form';
import { usePublicForm } from '@/forms/queries/usePublicForm';
import { useQuestions } from '@/forms/queries/useQuestions';
import { calculateQuestionPosition, groupByPage } from '@/lib/questions';
import { isAPIResponse } from '@/lib/request';
import { DefaultLoaderFallback, withFallback } from '@/lib/withFallback';
import { useUpdateDraftResponse } from '@/response/mutations/useUpdateDraftResponse';
import { useDraftResponse } from '@/response/queries/useDraftResponse';
import type { FormDraftResponse } from '@/response/types';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { isAxiosError } from 'axios';
import type { FallbackProps } from 'react-error-boundary';

function Page() {
  const { id: formId } = useParams({ from: '/forms/$id' });
  const form = usePublicForm({ id: formId });
  const questions = useQuestions({ formId });
  const draftResponse = useDraftResponse({ formId });
  const updateDraftResponse = useUpdateDraftResponse({ formId });
  const page =
    useSearch({
      from: '/forms/$id',
      select: (search) => search.page,
    }) ?? 1;
  const positionedFields = calculateQuestionPosition(questions.data ?? []);
  const groupedFields = groupByPage(positionedFields);
  const currentQuestions = groupedFields[page] ?? [];
  const totalPages = Object.keys(groupedFields).length;

  if (!form.data) {
    return null;
  }

  return (
    <ScrollArea className="flex justify-center items-center flex-col w-full pb-[56px] h-full">
      <div className="h-full w-full flex flex-col items-center">
        <FormPreview
          form={form.data}
          fields={currentQuestions}
          responses={draftResponse}
          onChangeResponse={(questionId, value) =>
            updateDraftResponse.mutateAsync({ questionId, value })
          }
          className="pb-[80px] max-w-[840px] w-full"
        />
        <div className="w-full flex justify-end max-w-[840px] px-[24px]">
          <QuestionPagination
            formId={form.data.id}
            page={page}
            totalPages={totalPages}
            response={draftResponse}
            fields={positionedFields}
          />
        </div>
      </div>
    </ScrollArea>
  );
}

function DefaultErrorFallback(props: FallbackProps) {
  const navigate = useNavigate();

  if (isAxiosError(props.error) && isAPIResponse(props.error.response?.data)) {
    return (
      <div className="flex flex-col gap-[32px]">
        <h1>Form not found</h1>
        <div>
          <Button onClick={() => navigate({ to: '/' })}>Back</Button>
        </div>
      </div>
    ); // TODO update view
  }

  return (
    <div>
      <h1>Something went wrong</h1>
    </div>
  );
}

export const ClientFormView = withFallback(
  Page,
  DefaultLoaderFallback,
  DefaultErrorFallback,
);

function QuestionPagination({
  page,
  formId,
  totalPages,
  fields,
  response,
}: {
  formId: string;
  page: number;
  totalPages: number;
  fields: PositionedField<Question>[];
  response: FormDraftResponse;
}) {
  const navigate = useNavigate();
  const isLastPageActive = page >= totalPages;

  return (
    <div className="flex gap-[8px] justify-center">
      <Button disabled={page <= 1} onClick={prevClick}>
        Prev
      </Button>
      {isLastPageActive ? (
        <ResponseSentDialog
          formId={formId}
          response={response}
          fields={fields}
        />
      ) : (
        <Button onClick={nextClick}>Next</Button>
      )}
    </div>
  );

  function nextClick() {
    navigate({
      to: '/forms/$id',
      params: { id: formId },
      search: { page: page + 1 },
    });
  }

  function prevClick() {
    navigate({
      to: '/forms/$id',
      params: { id: formId },
      search: { page: page - 1 },
    });
  }
}
