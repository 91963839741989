import { cn, isMetamaskError } from '@/lib/utils';
import { useSignUser } from '@/user/mutations/useSignUser';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useAccount } from 'wagmi';
import { Icon } from '../Icon';
import { buttonVariants } from '../ui/button';

export function WalletButton({
  children,
  variant,
}: {
  variant?: 'default' | 'secondary';
  children: (props: {
    address: string;
    openAccountModal: () => void;
  }) => React.ReactNode;
}) {
  const signUser = useSignUser();
  const [token, setToken] = useLocalStorage('token', '');
  const account = useAccount();

  if (account.isDisconnected) {
    setToken('');
  }

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
            className="h-full"
          >
            {(() => {
              if (!connected) {
                return (
                  <WalletItem
                    name="Connect wallet"
                    icon="wallet"
                    classNameText="font-[300]"
                    variant={variant ?? 'default'}
                    onClick={async () => {
                      try {
                        await signUser.mutateAsync();
                      } catch (error) {
                        if (isMetamaskError(error) && error.code === 4001) {
                          return;
                        }

                        openConnectModal();
                      }
                    }}
                  />
                );
              }

              if (chain.unsupported) {
                return (
                  <WalletItem
                    name="Change network"
                    icon="wallet"
                    variant="secondary"
                    classNameText="font-[300]"
                    // onClick={openChainModal}
                    onClick={async () => {
                      try {
                        await signUser.mutateAsync();
                      } catch (error) {
                        if (isMetamaskError(error) && error.code === 4001) {
                          return;
                        }

                        openChainModal();
                      }
                    }}
                  />
                );
              }

              if (!token) {
                return (
                  <WalletItem
                    name="Connect wallet"
                    icon="wallet"
                    classNameText="font-[300]"
                    variant={variant ?? 'default'}
                    onClick={async () => {
                      try {
                        await signUser.mutateAsync();
                      } catch (error) {
                        if (isMetamaskError(error) && error.code === 4001) {
                          return;
                        }

                        openConnectModal();
                      }
                    }}
                  />
                );
              }

              return (
                <div className="flex items-center gap-[8px]">
                  {children({ address: account.address, openAccountModal })}
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

interface WalletItemProps {
  name: string;
  variant?: 'default' | 'secondary';
  icon?: string;
  className?: string;
  classNameText?: string;
  onClick: () => void;
}

export function WalletItem(props: WalletItemProps) {
  return (
    <button
      type="button"
      className={cn(
        'group flex cursor-pointer items-center gap-[10px] hover:bg-[#212226] transition-colors rounded-[35px] py-[4px] pr-[4px] pl-[16px]',
        props.variant === 'secondary' &&
          buttonVariants({ variant: 'filled', size: 'medium' }),
        props.className,
      )}
      onClick={props.onClick}
    >
      <div
        className={cn(
          'font-[400] leading-[24px] text-1white text-[16px]',
          props.classNameText,
        )}
      >
        {props.name}
      </div>
      {props.icon && props.variant === 'default' && (
        <Icon name={props.icon} size={37} />
      )}
    </button>
  );
}
