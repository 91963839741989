import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { TextField } from '@/components/ui/textfield';
import { useCreateForm } from '@/forms/mutations/useCreateForm';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import { Icon } from './Icon';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui/tooltip';

export function CreateFormDialog({
  open,
  onChange,
}: { open: boolean; onChange: (open: boolean) => void }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const { address } = useAccount();
  const createForm = useCreateForm();

  return (
    <Dialog open={open} onOpenChange={onChange}>
      <DialogTrigger asChild className="max-w-[360px] w-full">
        <div className="flex w-full">
          <CreateNewFormButton />
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] border-border">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle>Create form</DialogTitle>
            <DialogClose className="p-[8px] hover:bg-[#2A2B2F] transition-colors">
              <Icon size={24} name="close" color="#CAC4D0" />
            </DialogClose>
          </div>
          <DialogDescription className="py-[16px]">
            Create new form and monetize results with&nbsp;
            <span className="font-[700]">Nuklai.</span>
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-[24px]">
          <div className="px-[16px] pt-[8px] pb-[12px] flex flex-col gap-[8px] bg-[#D0BCFF] text-[#4B4B4B] border border-[#49454F] rounded-[4px]">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-[8px]">
                <Icon
                  width={14}
                  height={16}
                  name="share-nodes"
                  color="#1D192B"
                />
                <span className="text-[16px] font-[500] leading-[24px] tracking-[.5px] text-[#1D192B]">
                  Community
                </span>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Icon size={24} name="circle-info" color="#1D192B" />
                  </TooltipTrigger>
                  <TooltipContent>
                    By creating a community dataset, you share the revenue of
                    the dataset with responders of this form. Every responder
                    will mint a fractionalized NFT representing their
                    contribution to this dataset
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="text-[14px] leading-[20px] font-[400] tracking-[.25px] text-[#381E72]">
              Create a form and monetize responders' submissions.
            </div>
          </div>
          <div className="flex flex-col gap-[8px] pb-[8px]">
            <Label
              htmlFor="title"
              className="text-[#CAC5CD] text-[16px] leading-[24px] tracking-[.25px] font-[400]"
            >
              Give it a name
            </Label>
            <TextField
              id="title"
              placeholder="Choose a name"
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
            />
          </div>
          <div className="flex flex-col gap-[8px]">
            <Label
              htmlFor="description"
              className="text-[#CAC5CD] text-[16px] leading-[24px] tracking-[.25px] font-[400]"
            >
              What is it for?
            </Label>
            <TextField
              id="description"
              placeholder="Provide a brief description"
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
              className="resize-none"
            />
          </div>
        </div>
        <DialogFooter className="pt-[40px]">
          <Button
            disabled={!validateInput() || createForm.isPending}
            type="submit"
            onClick={async () => {
              if (!address) return null;

              const form = await createForm.mutateAsync({
                title,
                description,
              });

              if (form.id) {
                navigate({
                  to: '/forms/$id/$mode',
                  params: { id: form.id, mode: 'edit' },
                });
              }
            }}
          >
            {createForm.isPending && (
              <>
                <Icon name="spinner" size={24} color="#1D192B" />
                &nbsp;
              </>
            )}
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  function validateInput() {
    return !!title && !!description;
  }
}

function CreateNewFormButton() {
  return (
    <Button className="flex flex-col items-center justify-center gap-[10px] w-full border border-[#3A393C] border-dashed rounded-[4px] h-[128px] bg-[#121212] hover:border-[#605D64] [&>p]:hover:text-[#CAC5CD] hover:bg-[#332D41]/[.12] active:bg-[#212226] [&>p]:active:text-[#CAC5CD] active:border-[#756B7D] [&_.mask]:hover:!bg-[#CAC5CD] [&_.mask]:active:!bg-[#CAC5CD] disabled:border-none disabled:bg-[#212226] [&>p]:disabled:text-[#48464C] [&_.mask]:disabled:!bg-[#69656E] [&_.mask]:!transition-colors">
      <Icon name="new-file" width={18} height={24} color="#938F96" />
      <p className="font-[400] text-[16px] text-[#938F96] leading-[24p] tracking-[.25px] transition-colors">
        Create a new form
      </p>
    </Button>
  );
}
