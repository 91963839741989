import * as React from "react";
import TextareaAutosize, {
  type TextareaAutosizeProps,
} from "react-textarea-autosize";

import { cn } from "@/lib/utils";
import { Icon } from "../Icon";

export interface TextareaProps extends TextareaAutosizeProps {
  resizable?: boolean;
  icon?: string;
  hint?: string;
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, resizable, icon, hint, error, ...props }, ref) => {
    const Component = resizable ? TextareaAutosize : "textarea";

    return (
      <div className="group relative w-full">
        <Component
          className={cn(
            "flex min-h-[72px] w-full rounded-[4px] border border-[#322F35] bg-transparent px-[16px] pt-[12px] pb-[4px] text-[14px] leading-[20px] tracking-[.25px] font-[400] placeholder:text-[#938F96] placeholder:group-hover:text-[#E6E0E9] focus-visible:outline-none focus-within:border-[2px] focus-within:pt-[11px] focus-within:pb-[3px] focus-within:px-[15px] disabled:cursor-not-allowed disabled:text-[#E6E0E9] disabled:border-[#938F99]/[.12] disabled:group-hover:!border-[#938F99]/[.12] disabled:placeholder:text-[#E6E0E9] transition-colors caret-[#938F99]",
            props.value &&
              "border-[#E6E0E9] text-[#E6E0E9] hover:!border-[#E6E0E9]",
            !error && "hover:border-[#48464C] focus-visible:!border-[#D0BCFF]",
            !!error &&
              "border-[#F2B8B5] hover:border-[#F2B8B5] focus-visible:border-[#F2B8B5]",
            !resizable && "resize-none",
            className
          )}
          ref={ref}
          {...props}
        />
        {(!!error || !!icon) && (
          <div
            className={cn(
              "absolute top-0 right-0 flex items-center p-[12px]",
              ((props.value && !error) || props.disabled) &&
                "[&_.mask]:!bg-[#E6E0E9]",
              !error && "[&_.mask]:group-hover:!bg-[#E6E0E9]"
            )}
          >
            <Icon
              name={error ? "info-circle" : icon}
              size={16}
              color={error ? "#F2B8B5" : "#938F96"}
              className="cursor-pointer"
            />
          </div>
        )}
        {(!!error || !!hint) && (
          <div
            className={cn(
              "text-[12px] font-[400] leading-[16px] px-[16px] pt-[4px]",
              hint && "text-[#CAC4D0]",
              error && "text-[#F2B8B5]"
            )}
          >
            {error || hint}
          </div>
        )}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
