import { EditFormTitle } from '@/components/EditFormTitle';
import { Icon } from '@/components/Icon';
import { toast } from '@/components/Toaster';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import type { Form } from '@/forms/domains/form';
import type { FormStats } from '@/forms/queries/useFormStats';
import { copyToClipBoard } from '@/lib/utils';
import { useDownloadResponse } from '@/response/mutations/useDownloadResponse';
import { useNavigate } from '@tanstack/react-router';

export function FormItemContextMenu({
  form,
  stats,
}: { form: Form; stats?: FormStats }) {
  const navigate = useNavigate();
  const downloadResponse = useDownloadResponse({ formId: form.id });
  const isPublished = form.status === 'published';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="outline-none hover:bg-[#2A2B2F] data-[state='open']:bg-[#2A2B2F] rounded-[4px] transition-colors">
        <Icon name="dots" size={22} color="#C4D1D9" className="p-[4px]" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() =>
            navigate({
              to: '/forms/$id',
              params: { id: form.id },
            })
          }
          disabled={!isPublished}
        >
          Open
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            copyToClipBoard(`${window.location.host}/forms/${form.id}`);
            toast({
              variant: 'clipboard',
              title: 'Link copied to Clipboard',
            });
          }}
          disabled={!isPublished}
        >
          Copy share link
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => downloadResponse.mutateAsync()}
          disabled={!stats?.total_responses}
        >
          Download responses
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <EditFormTitle formId={form.id}>
            <div className="text-[12px] leading-[16px] text-[#E6E0E9] px-[12px] py-[16px] hover:bg-[#E6E0E9]/[.08] transition-colors cursor-pointer">
              Rename
            </div>
          </EditFormTitle>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
