import type { Form } from '@/forms/domains/form';
import {
  type UpdateParams,
  useUpdateForm,
} from '@/forms/mutations/useUpdateForm';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { Textarea } from './ui/textarea';
import { TextField } from './ui/textfield';

type FormHeader = Pick<Form, 'id' | 'title' | 'description'>;

export function FormHeader({ form }: { form: FormHeader }) {
  const updateForm = useUpdateForm({ formId: form.id });
  const [editValue, setEditValue] = useState({
    title: form.title,
    description: form.description,
  });

  useEffect(
    () => setEditValue({ title: form.title, description: form.description }),
    [form],
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce((params: UpdateParams) => {
        updateForm.mutateAsync(params);
      }, 750),
    [updateForm.mutateAsync],
  );

  return (
    <div>
      <div className="h-[8px] bg-[#4A4458] rounded-t-[8px]">&nbsp;</div>
      <div className="bg-[#212226] px-[36px] pb-[48px] pt-[40px] flex flex-col gap-[24px] text-left rounded-b-[2px]">
        <TextField
          className="text-[32px] leading-[40px] text-[#FEFEFE] px-0 focus-visible:!pl-0 border-none"
          value={editValue.title}
          onChange={({ target: { value } }) => {
            debouncedUpdate({ title: value });
            setEditValue((prev) => ({ ...prev, title: value }));
          }}
        />
        <Textarea
          className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] px-0 focus-visible:!pl-0 focus-within:pt-[12px] min-h-fit resize-none border-none"
          value={editValue.description}
          onChange={({ target: { value } }) => {
            debouncedUpdate({ description: value });
            setEditValue((prev) => ({ ...prev, description: value }));
          }}
          resizable
        />
      </div>
    </div>
  );
}
