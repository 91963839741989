import type { PositionedField, Question } from '@/forms/domains/form';
import type { ReactNode } from 'react';
import { getContentDefinition } from '../AddFieldDialog/questionDefinition';
import { Icon } from '../Icon';
import { QuestionClientDescription } from '../QuestionEditableDescription';
import { QuestionClientTitle } from '../QuestionEditableTitle';

export function ReadableFieldWrapper({
  children,
  field,
}: {
  children: ReactNode;
  field: PositionedField<Question>;
}) {
  const questionDefinition = getContentDefinition(field.type);

  if (!questionDefinition) return null;

  return (
    <div className="px-[36px] flex flex-col gap-[16px]">
      <div className="flex w-full gap-[8px]">
        <div className="flex items-center gap-[8px]">
          <div
            className="text-[16px] font-[600] leading-[28px]"
            style={{ color: questionDefinition.backgroundColor }}
          >
            {field.indicator}
          </div>
          <Icon
            name="arrow-right"
            color={questionDefinition.backgroundColor}
            width={14}
            height={16}
          />
        </div>
        <QuestionClientTitle value={field.title} />
      </div>
      <div className="flex flex-col gap-[16px] pl-[40px]">
        {field.description && (
          <QuestionClientDescription value={field.description} />
        )}
        <div className="pl-[16px]">{children}</div>
      </div>
    </div>
  );
}
