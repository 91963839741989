import { useRouterState } from '@tanstack/react-router';

interface Breadcrumb {
  title: string;
  path: string;
}

export function useBreadcrumbs(): Breadcrumb[] {
  return useRouterState({
    select: (state) => {
      return state.matches
        .map((match) => {
          const title =
            match.id === '__root__'
              ? 'My workspace'
              : match.meta?.find((tag) => tag?.title)?.title;

          const path = match.id === '__root__' ? '/forms' : match.pathname;

          return {
            title,
            path,
          };
        })
        .filter((crumb) => Boolean(crumb.title)) as Breadcrumb[];
    },
  });
}
