import type { Question } from '@/forms/domains/form';
import { z } from 'zod';

const NotEmptyField = { message: 'Field cannot be empty' };

export function buildSchema(questions: Question[]) {
  return questions.reduce(
    (acc, question) => {
      if (question.type === 'short_text') {
        if (question.options.required) {
          acc[question.id] = z.string(NotEmptyField);

          return acc;
        }

        acc[question.id] = z.string().optional();

        return acc;
      }

      if (question.type === 'paragraph') {
        if (question.options.required) {
          acc[question.id] = z.string(NotEmptyField);

          return acc;
        }

        acc[question.id] = z.string().optional();

        return acc;
      }

      if (question.type === 'numerical') {
        if (question.options.required) {
          acc[question.id] = z.number({ message: 'Please enter a number' });

          return acc;
        }

        acc[question.id] = z.number().optional();
      }

      if (question.type === 'single_choice_list') {
        if (question.options.required) {
          acc[question.id] = z.string(NotEmptyField);

          return acc;
        }

        acc[question.id] = z.string().optional();
        return acc;
      }

      if (question.type === 'multi_choice_list') {
        if (question.options.required) {
          acc[question.id] = z.string(NotEmptyField);

          return acc;
        }

        acc[question.id] = z.string().optional();
        return acc;
      }

      return acc;
    },
    {} as Record<
      string,
      | z.ZodString
      | z.ZodOptional<z.ZodString>
      | z.ZodNumber
      | z.ZodOptional<z.ZodNumber>
    >,
  );
}
