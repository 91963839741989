import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "@/lib/utils";
import { Icon } from "../Icon";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer size-[18px] min-h-[18px] min-w-[18px] rounded-[2px] data-[state=unchecked]:border-[2px] data-[state=unchecked]:border-[#CAC4D0] data-[state=checked]:bg-[#D0BCFF] focus-visible:outline-none disabled:cursor-not-allowed data-[state=checked]:disabled:bg-[#E6E0E9] data-[state=unchecked]:disabled:border-[#E6E0E9] transition-colors",
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center")}
    >
      <Icon
        name="check-small"
        size={18}
        color={!props.disabled ? "#381E72" : "#141218"}
      />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
