import { useQuery } from '@tanstack/react-query';
import { type APIResponse, getFormsRequest } from '../../lib/request';
import type { Question } from '../domains/form';

export function useQuestions({ formId }: { formId?: string }) {
  return useQuery({
    queryKey: ['questions', formId],
    queryFn,
    enabled: !!formId,
  });

  async function queryFn() {
    const response = await getFormsRequest().get<APIResponse<Question[]>>(
      `/forms/${formId}/questions`,
    );

    return response.data.data ?? [];
  }
}
