import { CreateFormDialog } from '@/components/CreateFormDialog';
import { ReadonlySidebarLeft } from '@/components/ReadonlySidebarLeft';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { isMetamaskError } from '@/lib/utils';
import { useSignUser } from '@/user/mutations/useSignUser';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { FormTable } from './FormTable';

export function InvalidSession({
  resetErrorBoundary,
}: { resetErrorBoundary: () => void }) {
  const signUser = useSignUser();
  const { openConnectModal } = useConnectModal();

  return (
    <div className="flex h-full w-full">
      <ReadonlySidebarLeft />
      <ScrollArea className="flex flex-col w-full px-[36px] pb-[80px]">
        <div className="text-[20px] leading-[24px] tracking-[.25px] font-[400] text-[#CAC5CD] text-left py-[16px]">
          My workspace
        </div>
        <div className="pb-[24px]">
          <CreateFormDialog open={false} onChange={connectWallet} />
        </div>
        <FormTable formsData={[]} />
        <div className="flex justify-center">
          <div className="flex flex-col gap-[30px]">
            <h1>To continue, please connect your wallet</h1>
            <div>
              <Button onClick={connectWallet}>Connect wallet</Button>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );

  async function connectWallet() {
    try {
      await signUser.mutateAsync();
      resetErrorBoundary();
    } catch (error) {
      if (isMetamaskError(error) && error.code === 4001) {
        return;
      }

      openConnectModal?.();
    }
  }
}
