import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useRemoveQuestion({
  formId,
  questionId,
}: { formId: string; questionId: string }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['removeQuestion'],
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['questions', formId] });
    },
  });

  async function mutationFn() {
    return getFormsRequest().delete<APIResponse<null>>(
      `/forms/${formId}/questions/${questionId}`,
    );
  }
}
