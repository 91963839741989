import type { NewPageItem } from '@/forms/domains/form';
import {
  type UpdateParams,
  useUpdateQuestion,
} from '@/forms/mutations/useUpdateQuestion';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { Textarea } from './ui/textarea';
import { TextField } from './ui/textfield';

export function PageHeader({ item }: { item: NewPageItem }) {
  const updateQuestion = useUpdateQuestion({
    formId: item.formId,
  });
  const [editValue, setEditValue] = useState({
    title: item.title,
    description: item.description,
  });

  useEffect(
    () => setEditValue({ title: item.title, description: item.description }),
    [item],
  );

  const debouncedUpdate = useMemo(
    () =>
      debounce((params: UpdateParams) => {
        updateQuestion.mutateAsync(params);
      }, 750),
    [updateQuestion.mutateAsync],
  );

  return (
    <div>
      <div className="h-[8px] bg-[#4A4458] rounded-t-[8px]">&nbsp;</div>
      <div className="bg-[#212226] px-[36px] pb-[48px] pt-[40px] flex flex-col gap-[24px] text-left rounded-b-[2px]">
        <TextField
          className="text-[32px] leading-[40px] text-[#FEFEFE] px-0 focus-visible:!pl-0 border-none"
          value={editValue.title}
          placeholder="Add title"
          onChange={({ target: { value } }) => {
            debouncedUpdate({ title: value, questionId: item.id });
            setEditValue((prev) => ({ ...prev, title: value }));
          }}
        />
        <Textarea
          className="text-[16px] font-[400] leading-[24px] tracking-[.25px] text-[#CAC5CD] px-0 focus-visible:!pl-0 focus-within:pt-[12px] min-h-fit resize-none border-none"
          value={editValue.description}
          placeholder="Add description (optional)"
          onChange={({ target: { value } }) => {
            debouncedUpdate({ description: value, questionId: item.id });
            setEditValue((prev) => ({ ...prev, description: value }));
          }}
          resizable
        />
      </div>
    </div>
  );
}
