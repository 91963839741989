import './App.scss';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  RouterProvider,
  createRootRoute,
  createRoute,
  createRouter,
} from '@tanstack/react-router';
import { WagmiProvider } from 'wagmi';
import { z } from 'zod';
import { wagmiConfig } from './lib/walletConfig';
import { DashboardView } from './pages/DashboardView/DashboardView';
import { FormView } from './pages/EditFormView';
import { RootView } from './pages/RootView';
import '@rainbow-me/rainbowkit/styles.css';
import { isAxiosError } from 'axios';
import { Toaster } from './components/Toaster';
import { ClientFormView } from './pages/ClientFormView';
import { LandingView } from './pages/LandingView/LandingView';

const rootRoute = createRootRoute({
  component: RootView,
});

const indexSearchSchema = z.object({
  view: z.string().optional(),
});

const formSearchSchema = z.object({
  q: z.string().optional(),
  page: z.number().optional(),
});

const landingRoute = createRoute({
  path: '/',
  getParentRoute: () => rootRoute,
  component: LandingView,
});

const dashboardRoute = createRoute({
  path: '/forms',
  getParentRoute: () => rootRoute,
  component: DashboardView,
  staticData: () => [{ title: 'My workspace' }],
  validateSearch: indexSearchSchema,
});

const clientFormRoute = createRoute({
  path: '/forms/$id',
  getParentRoute: () => rootRoute,
  component: ClientFormView,
  staticData: () => [{ title: 'Form' }],
  validateSearch: formSearchSchema,
});

const formRoute = createRoute({
  path: '/forms/$id/$mode',
  getParentRoute: () => rootRoute,
  component: FormView,
  validateSearch: formSearchSchema,
});

const routeTree = rootRoute.addChildren([
  landingRoute,
  dashboardRoute,
  formRoute,
  clientFormRoute,
]);

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (isAxiosError(error)) {
          const status = error.response?.status;

          if (status === 401 || status === 404 || status === 500) {
            return false;
          }
        }

        return failureCount <= 2;
      },
    },
  },
});

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-right"
        />
        <RainbowKitProvider theme={darkTheme()}>
          <RouterProvider router={router} />
        </RainbowKitProvider>
      </QueryClientProvider>
      <Toaster />
    </WagmiProvider>
  );
}

export default App;
