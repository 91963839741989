import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type APIResponse, getFormsRequest } from '../../lib/request';
import type { Question } from '../domains/form';

interface CreateQuestionParams {
  title: string;
  type: string;
}

export function useCreateQuestion({ formId }: { formId: string }) {
  const client = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['questions', formId] });
    },
  });

  async function mutationFn({ question }: { question: CreateQuestionParams }) {
    const response = await getFormsRequest().post<APIResponse<Question>>(
      `/forms/${formId}/questions`,
      question,
    );

    return response.data.data;
  }
}
