import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";

export function QuestionEditableSupportingText({
  value,
  onChange,
}: { value: string; onChange: (value: string) => void }) {
  const [editValue, setEditValue] = useState(value);

  useEffect(() => setEditValue(value), [value]);

  const debouncedUpdate = useMemo(
    () => debounce((val) => onChange(val), 750),
    [onChange],
  );

  return (
    <input
      className="bg-transparent text-[12px] font-[400] leading-[16px] text-[#CAC4D0] outline-none w-full focus-within:!text-[#E6E0E9] caret-[#D0BCFF]"
      value={editValue}
      placeholder="Supporting text"
      onChange={({ target: { value } }) => {
        setEditValue(value);
        debouncedUpdate(value);
      }}
    />
  );
}

export function QuestionClientSupportingText({ value }: { value: string }) {
  return (
    <div className="text-[12px] font-[400] leading-[16px] text-[#CAC4D0] pt-[5px] pb-[3px]">
      {value}
    </div>
  );
}
