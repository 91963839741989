import { CreateFormButton } from './CreateFormButton';

export function ReadyToStart() {
  return (
    <section className="h-[241px] min-h-[241px] max-h-[241px] bg-[url('/images/landing-get-started-bg.svg')] bg-no-repeat bg-center flex justify-center items-center flex-col gap-[36px] pt-[42px]">
      <div className="text-[#D0BCFF] text-[40px] leading-[135%] font-[400] text-left">
        <span className="text-[#fff]">Ready to</span> get started?
      </div>
      <CreateFormButton />
    </section>
  );
}
