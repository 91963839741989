import { PopoverTrigger } from "@radix-ui/react-popover";
import { Popover, PopoverContent } from "./popover";
import { Calendar } from "./calendar";
import { TextField } from "./textfield";
import dayjs from "dayjs";

export function DatePicker({
  value,
  onChange,
}: {
  value?: string | null;
  onChange: (date?: string | null) => void;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild disabled={!value}>
        <TextField value={value ? dayjs(value).format("L") : ""} />
      </PopoverTrigger>
      {value && (
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={new Date(value)}
            onSelect={(date) => onChange(date?.toString())}
            initialFocus
          />
        </PopoverContent>
      )}
    </Popover>
  );
}
