type FormStatus = 'published' | 'draft';

interface QuestionBase {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  title: string;
  description: string;
  order: number;
  formId: string;
  schemaField: string;
}

export interface ShortTextItem extends QuestionBase {
  type: 'short_text';
  options: {
    required: boolean;
    supportingText: string;
  };
}

export interface ParagraphItem extends QuestionBase {
  type: 'paragraph';
  options: {
    required: boolean;
    supportingText: string;
  };
}

export interface NumericalItem extends QuestionBase {
  type: 'numerical';
  options: {
    required: boolean;
    supportingText: string;
  };
}

export interface DateItem extends QuestionBase {
  type: 'date';
  options: {
    required: boolean;
  };
}

export interface NewPageItem extends QuestionBase {
  type: 'new_page';
}

export interface ListOption {
  id: string;
  label: string;
}

export interface SingleChoiceListItem extends QuestionBase {
  type: 'single_choice_list';
  options: {
    required: boolean;
    listOptions: ListOption[] | null;
  };
}

export interface MultiChoiceListItem extends QuestionBase {
  type: 'multi_choice_list';
  options: {
    required: boolean;
    listOptions: ListOption[] | null;
  };
}

export type Question =
  | ShortTextItem
  | ParagraphItem
  | NumericalItem
  | DateItem
  | SingleChoiceListItem
  | MultiChoiceListItem
  | NewPageItem;

type Position = {
  indicator: number | null;
};

export type PositionedField<Q extends Question> = Q & Position;

export function hasOptions(question: Question) {
  return 'options' in question;
}

export interface Form {
  id: string;
  title: string;
  description: string;
  status: FormStatus;
  ownerId: string;
  datasetId: string;
  campaignEndsAt: string | null;
  updatedAt: string;
  createdAt: string;
}
