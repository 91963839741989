import type { PositionedField, Question } from '@/forms/domains/form';

export function calculateQuestionPosition<T extends Question>(
  questions: T[],
): PositionedField<T>[] {
  let lastIndicator = 0;
  return questions.map((question) => {
    if (question.type !== 'new_page') {
      lastIndicator += 1;
    }
    const positionedField = {
      ...question,
      indicator: question.type !== 'new_page' ? lastIndicator : null,
    };

    return positionedField;
  });
}

export function groupByPage<Q extends Question>(
  fields: PositionedField<Q>[],
): Record<number, PositionedField<Q>[]> {
  let lastPage = 1;

  return fields.reduce(
    (acc, field) => {
      if (field.type === 'new_page') {
        lastPage += 1;
      }

      if (lastPage in acc) {
        acc[lastPage].push(field);
      } else {
        acc[lastPage] = [field];
      }

      return acc;
    },
    {} as Record<number, PositionedField<Q>[]>,
  );
}
