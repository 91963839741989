import { cn } from '@/lib/utils';
import type { ContentDefinition } from './AddFieldDialog/questionDefinition';
import { Icon } from './Icon';

export function QuestionIndicator({
  definition,
  order,
  className,
}: { definition: ContentDefinition; order: number; className?: string }) {
  return (
    <div
      className={cn(
        'rounded-[3px] py-[1px] px-[5px] flex items-center justify-center gap-[18px]',
        className,
      )}
      style={{ backgroundColor: definition.backgroundColor }}
    >
      <div className="min-w-[16px]">
        <Icon
          name={definition.icon}
          width={definition.width}
          height={definition.height}
        />
      </div>
      <div className="text-[14px] font-[500] leading-[20px] tracking-[.25px] text-[#121212]">
        {order}
      </div>
    </div>
  );
}
