import { hasOptions } from '@/forms/domains/form';
import { useUpdateQuestion } from '@/forms/mutations/useUpdateQuestion';
import { useQuestion } from '@/forms/services/useQuestion';
import { Label } from '../ui/label';
import { Switch } from '../ui/switch';

export function QuestionSettings({
  formId,
  questionId,
}: { formId: string; questionId: string }) {
  const question = useQuestion({ formId, questionId });
  const updateQuestion = useUpdateQuestion({ formId });

  if (!question || !hasOptions(question)) return null;

  return (
    <div className="pt-[16px]">
      <SwitchItem
        label="Required"
        value={question.options.required}
        onChange={(value) =>
          updateQuestion.mutateAsync({
            questionId: question.id,
            options: {
              ...question.options,
              required: value,
            },
          })
        }
      />
    </div>
  );
}

function SwitchItem({
  label,
  value,
  onChange,
}: { label: string; value: boolean; onChange: (value: boolean) => void }) {
  return (
    <div className="flex items-center justify-between px-[24px] py-[10px] w-full">
      <Label
        htmlFor={label}
        className="text-[#CAC5CD] text-[14px] leading-[20px] tracking-[.25px] font-[300] cursor-pointer"
      >
        {label}
      </Label>
      <Switch id={label} checked={value} onCheckedChange={onChange} />
    </div>
  );
}
