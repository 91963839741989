import * as React from "react";

import { cn } from "@/lib/utils";
import { Icon } from "../Icon";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
  error?: string;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, icon, error, ...props }, ref) => {
    return (
      <div className="group relative w-full">
        <input
          type="text"
          className={cn(
            "flex h-[40px] w-full bg-transparent rounded-[4px] border border-[#938F96] transition-colors px-[16px] focus-visible:!pl-[15px] focus-within:!pr-[15px] py-[4px] text-[14px] tracking-[.25px] leading-[20px] font-[500] placeholder:text-[#938F96] placeholder:group-hover:text-[#E6E0E9] focus-visible:outline-none focus-within:border-[2px] disabled:cursor-not-allowed disabled:border-[#938F99]/[.12] disabled:text-[#E6E0E9] disabled:placeholder:text-[#E6E0E9]",
            props.value && "border-[#E6E0E9] text-[#E6E0E9]",
            !error && "hover:border-[#E6E0E9] focus-visible:border-[#D0BCFF]",
            !!error && "border-[#F2B8B5]",
            className
          )}
          ref={ref}
          {...props}
        />
        {(!!icon || !!error) && (
          <div
            className={cn(
              "absolute top-0 right-0 flex items-center p-[12px]",
              ((props.value && !error) || props.disabled) &&
                "[&_.mask]:!bg-[#E6E0E9]",
              !error && "[&_.mask]:group-hover:!bg-[#E6E0E9]"
            )}
          >
            <Icon
              name={error ? "info-circle" : icon}
              size={16}
              color={error ? "#F2B8B5" : "#938F96"}
              className="cursor-pointer"
            />
          </div>
        )}
        {!!error && (
          <div className="flex items-start text-[12px] text-[#F2B8B5] font-[400] leading-[16px] px-[16px] pt-[4px]">
            {error}
          </div>
        )}
      </div>
    );
  }
);
TextField.displayName = "TextField";

export { TextField };
