import { useLocalStorage } from '@uidotdev/usehooks';
import type { FormDraftResponse } from '../types';

export function useDraftResponse({ formId }: { formId: string }) {
  const [draftResponse] = useLocalStorage<FormDraftResponse>(
    `form-${formId}-draft`,
    {},
  );

  return draftResponse;
}
