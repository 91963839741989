import { cn, onKeyboardAction, scrollToEditorBottom } from '@/lib/utils';

import type { Form } from '@/forms/domains/form';
import { useCreateQuestion } from '@/forms/mutations/useCreateQuestion';
import { PopoverClose } from '@radix-ui/react-popover';
import { useNavigate, useParams } from '@tanstack/react-router';
import { Icon } from '../Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import {
  type ContentDefinition,
  questionDefinition,
} from './questionDefinition';

export function AddFieldPanel({ form }: { form: Form }) {
  const createQuestion = useCreateQuestion({ formId: form.id });
  const navigate = useNavigate();
  const { mode } = useParams({ from: '/forms/$id/$mode' });

  return (
    <Popover>
      <PopoverTrigger asChild disabled={form.status === 'published'}>
        <div
          className={cn(
            "cursor-pointer transition-colors rounded-[4px] hover:!bg-[#FFFFFF0A] data-[state='open']:!bg-[#FFFFFF0A] size-[36px] flex justify-center items-center",
            isDialogDisabled() && 'cursor-not-allowed opacity-50',
          )}
          onClick={openDialog}
          onKeyDown={onKeyboardAction(openDialog)}
          title={getReasonForDisabled()}
        >
          <Icon size={20} name="add" color="#FFF" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-[440px] bg-[#212226] rounded-[8px] border-none px-[24px] pt-0 pb-[24px]"
        side="right"
        sideOffset={20}
        align="start"
        alignOffset={10}
      >
        <div className="py-[16px] text-[16px] text-[#CAC5CD] font-[400] leading-[24px] tracking-[.25px]">
          Choose your content type
        </div>
        <div className="border border-[#322F35] rounded-[4px]">
          {questionDefinition.map((definition, index) => (
            <Item
              key={definition.id}
              questionType={definition}
              onClick={addItem}
              className={cn(
                index === 0 && 'rounded-t-[4px]',
                index === questionDefinition.length - 1 && 'rounded-b-[4px]',
              )}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  async function addItem(selected: string) {
    const question = await createQuestion.mutateAsync({
      question: {
        title: 'Question',
        type: selected,
      },
    });

    scrollToEditorBottom();

    navigate({
      to: '/forms/$id/$mode',
      params: { id: question.formId, mode },
      search: { q: question.id },
    });
  }

  function openDialog(
    e?:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) {
    if (isDialogDisabled()) e?.preventDefault();
  }

  function isDialogDisabled() {
    return form.status === 'published';
  }

  function getReasonForDisabled() {
    if (form.status === 'published') {
      return "You can't add fields to a published form";
    }

    return '';
  }
}

function Item({
  questionType,
  onClick,
  className,
}: {
  questionType: ContentDefinition;
  onClick: (selected: string) => void;
  className?: string;
}) {
  return (
    <PopoverClose asChild>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
      <div
        className={cn(
          'px-[16px] pt-[8px] pb-[12px] cursor-pointer flex hover:bg-[#D0BCFF] group transition-colors',
          className,
        )}
        onClick={() => onClick(questionType.id)}
      >
        <div className="gap-[10px] flex flex-col">
          <div className="text-[16px] font-[400] text-white leading-[24px] tracking-[.25px] flex items-center gap-[8px] py-[4px] group-hover:text-[#2B2930] group-hover:font-[500]">
            <TypeIcon definition={questionType} />
            {questionType.title}
          </div>
          <div className="text-[14px] text-[#938F96] font-[400] leading-[20px] tracking-[.25px] group-hover:text-[#322F35]">
            {questionType.description}
          </div>
        </div>
      </div>
    </PopoverClose>
  );
}

function TypeIcon({
  definition,
}: {
  definition: ContentDefinition;
}) {
  const hasIcon = definition.icon && definition.height && definition.width;

  return (
    <div
      className="w-[24px] h-[24px] flex items-center justify-center rounded-[2px]"
      style={{ backgroundColor: definition.backgroundColor }}
    >
      {hasIcon && (
        <Icon
          width={definition.width}
          height={definition.height}
          name={definition.icon}
        />
      )}
    </div>
  );
}
