import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";
import { Textarea } from "./ui/textarea";

export function FormEditableDescription({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const [editValue, setEditValue] = useState(value);

  useEffect(() => setEditValue(value), [value]);

  const debouncedUpdate = useMemo(
    () => debounce((val) => onChange(val), 750),
    [onChange]
  );

  return (
    <Textarea
      value={editValue}
      placeholder="Add description (optional)"
      onChange={({ target: { value } }) => {
        setEditValue(value);
        debouncedUpdate(value);
      }}
      className="pt-[8px] min-h-fit resize-none border-transparent hover:!border-transparent focus-visible:!border-transparent placeholder:italic focus-within:pt-[7px]"
      resizable
    />
  );
}

export function QuestionClientDescription({ value }: { value: string }) {
  return (
    <div className="text-[14px] font-[500] leading-[20px] tracking-[.25px] text-[#E6E0E9] text-left px-[16px] pt-[8px] pb-[4px] border border-transparent whitespace-pre">
      {value}
    </div>
  );
}
