import { type APIResponse, getFormsRequest } from "@/lib/request";
import { formatDate } from "@/lib/utils";
import { useMutation } from "@tanstack/react-query";

export function useDownloadResponse({ formId }: { formId: string }) {
  return useMutation({
    mutationKey: ['downloadResponse'],
    mutationFn,
  });

  async function mutationFn() {
    const response = await getFormsRequest().get<APIResponse<unknown>>(`/forms/${formId}/responses`);

    downloadResponses(`responses-${formId}`, response.data.data);
  }
}

export function downloadResponses(title: string, data: unknown): void {
  downloadFile(title.replaceAll(' ', '_'), data);
}

function downloadFile(prefix: string, data: unknown) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
  const dlAnchorElem = document.createElement('a');
  dlAnchorElem.setAttribute('href', dataStr);
  dlAnchorElem.setAttribute(
    'download',
    `${prefix}_${formatDate(new Date().toISOString(), 'YYYY-MM-DD-HHmmss')}.json`,
  );
  dlAnchorElem.click();
}