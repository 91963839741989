import { type APIResponse, getFormsRequest } from '@/lib/request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import type { Form } from '../domains/form';

export interface UpdateParams {
  title?: string;
  description?: string;
  campaignEndsAt?: string | null;
  status?: string;
}

export function useUpdateForm({ formId }: { formId: string }) {
  const client = useQueryClient();
  const { address } = useAccount();

  return useMutation({
    mutationKey: ['updateForm'],
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['form', formId] });
      client.invalidateQueries({ queryKey: ['myForms', address] });
    },
  });

  async function mutationFn(updateParams: UpdateParams) {
    const response = await getFormsRequest().patch<APIResponse<Form>>(
      `/forms/${formId}`,
      updateParams,
    );

    return response.data.data;
  }
}
